import { Component,OnInit, ViewChild, ElementRef, NgZone, Input } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { } from '@agm/core/services/google-maps-types';

import { UserService } from '../../../SharedResources/Services/user.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { errorHandlerService } from '../../../SharedResources/Services/errorHandler.service';
import { Router } from '@angular/router';
import { environment } from "../../../../environments/environment";
import { sharedService } from '../../../SharedResources/Services/shared.service';
import { Subscription } from 'rxjs';
import { SeoService } from '../../../SharedResources/Services/seoService';
import { HomeService } from '../../../SharedResources/Services/home.service';
declare const $;

@Component({
    selector:'profile-address',
    templateUrl: './profile-address.html',
})

export class profileAddressComponent implements OnInit {
    public addressList: any = [];
    public first_name: string;
    public last_name: string="";
    public id: any;
    public email_id: string;
    public company: string="";
    public mobile_number: string;
    public country_code: string="+973";
    public alternate_country_code: string="+973";
    public optional_mobile_number:string="";
    public location: any;
    public landmark: any;
    public city_id: any;
    public location_type: string="1";
    public err: boolean=false;
    public load: boolean=false;
    public latitude = 24.774265;
    public longitude = 46.738586;
    public lat: any;
    public long: any;
    public addressClicked: boolean = false;
    public geoCoder: any;
    public address: string;
    public address_type:string="1"
    public address_count:string;
    @Input() redirect: boolean;
    public addressError:any={
        "first_name":false,
        "email_id":false,
        "email_id_valid":false,
        "mobile_number":false,
        "mobile_number_valid":false,
        "address":false,
        "optional_mobile_number_valid":false,
        "location_type":false,
    }
    
    @ViewChild("search", { static: false })
    public searchElementRef: ElementRef;
    public draggable:boolean=true
    public LANG:any;
    public subscriptions:Subscription[]=[]
    public zoom: number = 8;



   

    constructor(private user:UserService,private mapsAPILoader: MapsAPILoader, private ngZone: NgZone,private toast:ToastrManager,private error:errorHandlerService,private router:Router,private shared:sharedService,private seo:SeoService,private home:HomeService){
        this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
            this.changeLanguage();
            this.getAddressList()
        }))
        this.changeLanguage();
        if(localStorage.getItem('mobile_number') != undefined){
            const mobile=localStorage.getItem('mobile_number');
            const country_code=localStorage.getItem('country_code');
            this.mobile_number=mobile;
            this.country_code=country_code
        }
        window.scroll(0,0)
    }

    ngOnInit(){
        this.getAddressList()
        this.getAutoComplete()
    }

    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
        }
        else {
          this.LANG = environment.english_translation;
        }
        this.getSeoDetails()
      }

    getAddressList(){
        this.subscriptions.push(this.user.getUserAddress().subscribe(result=>{
                if(result){
                    this.addressList=result['response']
                }
            }))
    }

    getAutoComplete() {
        this.mapsAPILoader.load().then(() => {
            this.geoCoder = new google.maps.Geocoder;
            let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
            autocomplete.addListener("place_changed", () => {
                this.ngZone.run(() => {
                    let place: google.maps.places.PlaceResult = autocomplete.getPlace();

                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    this.lat = place.geometry.location.lat();
                    this.long = place.geometry.location.lng();
                    this.latitude = place.geometry.location.lat();
                    this.longitude = place.geometry.location.lng();
                    this.address = place.formatted_address;
                    this.addressError.address=false;
                    this.addressClicked = true;
                    this.zoom=18
                });
            });
        });
    }

    onSelectAddress(event) {
        this.latitude = event.coords.lat;
        this.longitude = event.coords.lng;
        this.getAddress(event.coords.lat, event.coords.lng)
        this.addressClicked = true;
        this.addressError.address=false
    }

    markerDragEnd(event){
        this.latitude = event.coords.lat;
        this.longitude = event.coords.lng;
        this.getAddress(event.coords.lat, event.coords.lng)
        this.addressClicked = true;
        this.addressError.address=false
    }

    getAddress(latitude, longitude) {
        this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
            if (status == 'OK') {
                if (results[0]) {
                    this.lat = latitude;
                    this.long = longitude;
                    this.address = results[0].formatted_address;
                    this.zoom=18
                } else {
                    this.toast.warningToastr("",this.LANG.no_result_found, { position: "top-right", toastTimeout: 3000 });
                }
            } else {
                this.toast.errorToastr(this.LANG.geocoder_failed_due_to + status, "Error!", { position: "top-right", toastTimeout: 3000 });
            }

        });
    }

    addAddress(){
        this.resetAddressError()
        this.clearAddressFields()
    }

    saveAddress(){
        this.err=false;
        this.resetAddressError()
        this.errorHandler()
        if(!this.err){
            this.load=true
            this.checkCity(result=>{
                if(result){
                    let data = {
                        "id": this.id,
                        "first_name": this.first_name,
                        "last_name": this.last_name,
                        "address_1": this.address,
                        "address_2": this.address,
                        "pincode": "",
                        "company":this.company,
                        "email_id":this.email_id,
                        "latitude": this.lat,
                        "longitude": this.long,
                        "country_code":this.country_code,
                        "phone": this.mobile_number,
                        "type": this.address_type,
                        "alternate_phone":this.optional_mobile_number,
                        "city_id": this.city_id,
                        "action": "1",
                        "address_count": this.addressList.length
                    }
                    if(this.id){
                        data.action="2"
                        this.updateUserAddress(data)
                    }else{
                        this.saveUserAddress(data)
                    }
                }
            })
        }
    }

    saveUserAddress(data){
        this.subscriptions.push(this.user.saveAddress(data).subscribe(result => {
            if(result.status){
                this.getAddressList();
                $('#address_picker').modal('hide');
                if(data.action == "1"){
                    this.toast.successToastr(this.LANG.address_added_successfully,"",{position:'top-right',toastTimeout:3000})
                }
                this.load=false
                if(this.redirect){
                    this.router.navigate(['/checkout'])
                }
            }else{
                this.load=false
                this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
            }
        },respagesError => {
            this.load=false
            const error = this.error.getError(respagesError);
            if(error == "Gateway timeout"){
                return
              }
            this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
        }))
    }

    updateUserAddress(data){
        this.subscriptions.push(this.user.saveAddress(data).subscribe(result => {
            if(result.status){
                this.getAddressList();
                if(data.action == "2"){
                    this.toast.successToastr(this.LANG.address_updated_successfully,"",{position:'top-right',toastTimeout:3000})
                }else{
                    this.toast.successToastr(this.LANG.address_removed_successfully,"",{position:'top-right',toastTimeout:3000})
                }
                $('#address_picker').modal('hide');
                this.load=false
            }else{
                this.load=false
                this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
            }
        },respagesError => {
            this.load=false
            const error = this.error.getError(respagesError);
            if(error == "Gateway timeout"){
                return
              }
            this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
        }))
    }

    resetAddressError(){
        this.addressError={
            "first_name":false,
            "mobile_number":false,
            "mobile_number_valid":false,
            "optional_mobile_number_valid":false,
            "address":false,
            "location_type":false,
        } 
    }

    clearAddressFields(){
                this.load=false
                this.id=""
                this.first_name=""
                this.last_name=""
                this.address=""
                this.company=""
                this.email_id=""
                this.lat=""
                this.long=""
                this.latitude = 24.774265;
                this.longitude = 46.738586;
                this.optional_mobile_number=""
                this.city_id=""
                this.addressClicked=false;
                this.zoom=8
    }

    closeModal() {
        $('#address_picker').modal('hide');
    }

    checkCity(callback){
        const data={
         "lat":this.lat,
         "long":this.long
        }
        this.subscriptions.push(this.user.checkCity(data).subscribe(results=>{
            if(results['id']){
             this.city_id=results['id']
                callback(true)
            }else if(!results.status){
                this.load=false
                this.toast.warningToastr(results['message'],"",{ position: "top-right", toastTimeout: 3000 })
            }
        },respagesError => {
         this.load=false
         const error = this.error.getError(respagesError);
         if(error == "Gateway timeout"){
             return
           }
         this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
     }))
     }

    errorHandler(){
        if(this.first_name == undefined || this.first_name == ""){
            this.addressError.first_name=true;
            this.err=true;
        }

        // if(this.email_id == undefined || this.email_id == ""){
        //     this.addressError.email_id=true;
        //     this.err=true;
        // }

        if(this.checkEmail(this.email_id) && this.email_id){
            this.addressError.email_id_valid=true;	
            this.err=true;
        }

        if (this.address == "" || this.address == undefined || this.lat == "" || this.long == "" || this.lat == undefined || this.long == undefined) {
            this.addressError.address = true;
            this.err = true;
        }

        if(!this.addressError.mobile_number && this.checkMobileNumber(this.mobile_number)){
            this.addressError.mobile_number_valid=true;	
            this.err=true;
        }

        if (this.optional_mobile_number && this.checkMobileNumber(this.optional_mobile_number)) {
            this.addressError.optional_mobile_number_valid = true;
            this.err = true;
        }

        // if(this.alternate_country_code == "+966"){
        //     const re=/^([0]{1}[5]{1}[0-9]*)$/
        //     const re1=/^([5]{1}[0-9]*)$/
        //     if(this.optional_mobile_number && !re.test(this.optional_mobile_number) && !re1.test(this.optional_mobile_number)){
        //       this.addressError.optional_mobile_number_valid=true;
        //       this.err=true;	
        //     }
    
        //     if(this.optional_mobile_number && re.test(this.optional_mobile_number) && this.optional_mobile_number.length != 10){
        //       this.addressError.optional_mobile_number_valid=true;
        //       this.err=true;	
        //     }
    
        //     if(this.optional_mobile_number && re1.test(this.optional_mobile_number) && this.optional_mobile_number.length != 9){
        //       this.addressError.optional_mobile_number_valid=true;
        //       this.err=true;	
        //     }
        //   }
    }

    checkEmail(email:string){
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(email)
    }

     

    checkMobileNumber(mobile:string){
        if(mobile.length < 8 || mobile.length > 10){
            return true
        }
    }

    changeSelectedAddress(data){
        data.type="1"
        data.action="2"
        this.saveUserAddress(data)
    }

    getAddressDetails(id){
        this.subscriptions.push(this.user.getAddressDetails(id).subscribe(result=>{
            if(result['id']){
                    this.id=result['id'];
                    this.first_name=result['first_name'];
                    this.last_name=result['last_name'];
                    this.address=result['address_1'];
                    this.company=result['company'];
                    this.email_id=result['email_id'];
                    this.mobile_number=result['phone'];
                    this.address_type=result['type'];
                    this.optional_mobile_number=result['alternate_phone'];
                    this.city_id=result['city_id'];
                    this.lat=result['latitude'];
                    this.long=result['longitude'];
                    this.latitude = result['latitude'] || 24.774265;
                    this.longitude = result['longitude'] || 46.738586;
                    this.addressClicked=true
                    $('#address_picker').modal('show');
            }
        }))
    }

    deleteAddress(data){
        data.action="3"
        this.updateUserAddress(data)
    }

    onlyNumbers(event){
        var keycode = (event.which) ? event.which : event.keyCode;
        if ((keycode < 48 || keycode > 57) && keycode !== 13 || keycode == 46) {
           event.preventDefault();
           return false;
       }    
     }

     countryCode(country){
        if(country == "SA"){
          this.country_code="+973"
        }
  }

  alternateCountryCode(country){
     if(country == "SA"){
      this.alternate_country_code="+973"
    }
    this.addressError.optional_mobile_number_valid=false
}

clearSelectedAddress(){       
    this.lat=""
    this.long=""
}

ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }

  getSeoDetails(){
    this.subscriptions.push(this.home.getSeoDetals("12").subscribe(result=>{
      if(result){
        this.seo.updateMetaTitle(result['response'])
      }
    }))
  }

  useCurrentLocation(){
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.setGeoAddress);
      } else {
        this.toast.warningToastr("Geolocation is not supported by this browser.","",{position:'top-right',toastTimeout:3000})
      }
  }

  setGeoAddress=(event)=> {
    this.latitude = event.coords.latitude ;
    this.longitude = event.coords.longitude;
    this.getAddress(event.coords.latitude , event.coords.longitude)
    this.addressClicked = true;
    this.addressError.address=false
}
   
} 