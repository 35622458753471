import {Injectable} from '@angular/core';
import * as md5 from 'md5'
import * as SHA1 from 'sha1'

@Injectable({providedIn: 'root'})
export class configServiceComponent{
	

	HOST : string = "https://saadeddin.com.bh/api/app/v0_1/api/";
	// HOST : string = "http://sapi.saadeddin.com/bahrain/app/v0_1/api/";



	USERID : string = "	";
	USERNAME : string = "";
	PASSWORD : string = "";
	TIME : string


	// development
	ASSESTSPATH : any = "../assets/";

	 constructor(){
	}

	
	getHOST(){
		return this.HOST;
	}

	encryptPassword(password : any ){
		return md5(password);
	}


	encryptValue(value : any ){
		return window.btoa(value);
	}


	decryptValue(value : any ){
		return window.atob(value);
	}

	  getAuthHeaders(routeUrl :any){
		// let finalAuth = SHA1(this.HOST + routeUrl +"|"+ this.USERNAME +"|"+ this.PASSWORD +"|"+ this.NOW);
		let finalAuth = SHA1(this.HOST + routeUrl +"|"+ this.USERNAME +"|"+ this.PASSWORD);
			return finalAuth
	}
	

		

		   

		
	
}