import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule} from '@angular/forms';
import { AngularFireModule } from "@angular/fire";
import {AngularFireStorageModule} from "@angular/fire/storage";
import { CommonModule } from '@angular/common';
import { loaderModule } from './loader.module';
import { profileComponent } from '../pages/Profile/MyAccount/profile';
import { profileAddressComponent } from '../pages/Profile/Address/profile-address';
import {AgmCoreModule} from '@agm/core';
import { authGuard } from '../SharedResources/Services/authGuard.service';
import { environment } from 'src/environments/environment';
import { profileOrderListComponent } from '../pages/Profile/Orders/OrderList/profile-orderList';
import { profileOrderDetailsComponent } from '../pages/Profile/Orders/OrderDetails/profile-orderDetails';




const ChildRoutes: Routes = [
  {
    path: 'my-profile',
    component:profileComponent,
    canActivate : [authGuard]
  }
  ]

@NgModule({
  imports: [
    RouterModule.forChild(ChildRoutes),
    FormsModule,
    CommonModule,
    loaderModule,
    AngularFireStorageModule,
    AgmCoreModule.forRoot({
      apiKey:"AIzaSyBxu6gRfla-EaaKmiR7afHqnzxjLbEIi40",
      libraries: ['places']
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig, "cloud")
  ],
  declarations:[
    profileComponent,
    profileAddressComponent,
    profileOrderListComponent,
    profileOrderDetailsComponent,
  ]
})
export class profileModule { }
