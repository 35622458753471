import {Injectable} from '@angular/core';
import {apiServiceComponent} from './api.service';


@Injectable({providedIn: 'root'})
export class OrderService{
    private url : string = "";

	constructor(private api : apiServiceComponent){
    }


    orderSubmit(data){
        this.url = "order_submit";
        return this.api.post(this.url, data); 
    }

    orderList(index,size){
        this.url = "order_list";
        let query= "?index="+index+"&size="+size
        return this.api.get(this.url, query); 
    }

    orderDetails(order_id){
        this.url = "order_detail";
        let query= "?id="+order_id;
        return this.api.get(this.url, query); 
    }

    paymentGateway(data){
        this.url="payfort"
        return this.api.post(this.url, data);
    }

    checkPayment(data){
        this.url="check_payment"
        return this.api.post(this.url, data);
    }

    deliveryTime(data){
        this.url="delivery_time"
        return this.api.post(this.url, data);
    }

    feedback(data){
        this.url = "insert_feedback";
        return this.api.post(this.url, data); 
    }
    
}