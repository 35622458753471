import { Component,OnInit } from '@angular/core';
import { HomeService } from '../../SharedResources/Services/home.service';
import { sharedService } from '../../SharedResources/Services/shared.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from "../../../environments/environment";
import { SeoService } from '../../SharedResources/Services/seoService';
import { Subscription } from 'rxjs';

declare const $;

@Component({
    templateUrl: './contact.html', 
})

export class contactUsComponent implements OnInit {
    public err:boolean=false
    public load:boolean=false
    public country_code: string="+973";
    public contact_details:any={}
    public nature_of_enquiry:string;
    public full_name:string;
    public mobile_number:string;
    public email_id:string;
    public message:string;
    public LANG:any;
    public subscriptions:Subscription[]=[]
    public assets_path="https://saadeddin-production-static.s3.ap-south-1.amazonaws.com/assets"



    public contactError:any={
        "full_name":false,
        "email_id":false,
        "email_id_valid":false,
        "mobile_number":false,
        "mobile_number_valid":false,
        "nature_of_enquiry":false,
        "message":false,
    }


    constructor(private home:HomeService,private toast:ToastrManager,private shared:sharedService,private seo:SeoService){
        this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
            if(location.pathname.includes("contact-us")){
                this.changeLanguage();
                this.getContactDetails()
            }
          }))
    }

    ngOnInit(){
        this.getContactDetails()
        this.changeLanguage();
        window.scroll(0,0)
    }

//Change Language from arabic to english and vice-versa
    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
        }
        else {
          this.LANG = environment.english_translation;
        }
        this.getSeoDetails()
      }

    //get Website Contact details dynamically  
    getContactDetails(){
        this.subscriptions.push(this.home.contact().subscribe(result=>{
            if(result){
                this.contact_details=result
                setTimeout(() => {
                    $('select').niceSelect();
                }, 100);
            }
        }))
    }

    errorHandler(){
        if(this.full_name == undefined || this.full_name == ""){
            this.contactError.full_name=true;
            this.err=true;
        }
        if(this.email_id == undefined || this.email_id == ""){
            this.contactError.email_id=true;
            this.err=true;
        }
        if(this.nature_of_enquiry == ""  || this.nature_of_enquiry == undefined){
            this.contactError.nature_of_enquiry=true;	
            this.err=true;
        }

        if(this.mobile_number == ""  || this.mobile_number == undefined){
            this.contactError.mobile_number=true;	
            this.err=true;
        }

        if(!this.contactError.mobile_number && this.checkMobileNumber(this.mobile_number)){
            this.contactError.mobile_number_valid=true;
            this.err=true;	 
        }

        // if(this.country_code == "+966"){
        //     const re=/^([0]{1}[5]{1}[0-9]*)$/
        //     const re1=/^([5]{1}[0-9]*)$/
        //     if(this.mobile_number && !re.test(this.mobile_number) && !re1.test(this.mobile_number)){
        //       this.contactError.mobile_number_valid=true;
        //       this.err=true;	
        //     }
    
        //     if(this.mobile_number && re.test(this.mobile_number) && this.mobile_number.length != 10){
        //       this.contactError.mobile_number_valid=true;
        //       this.err=true;	
        //     }
    
        //     if(this.mobile_number && re1.test(this.mobile_number) && this.mobile_number.length != 9){
        //       this.contactError.mobile_number_valid=true;
        //       this.err=true;	
        //     }
        // }

        if(this.checkEmail(this.email_id) && !this.contactError.email_id){
            this.contactError.email_id_valid=true;	
            this.err=true;
        }

        if(this.message == "" || this.message == undefined){
            this.contactError.message=true;	
            this.err=true;
        }
    }

    checkEmail(email:string){
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(email)
    }

    checkMobileNumber(mobile:string){
        if(mobile.length <8 || mobile.length > 10){
            return true
        }
    }

    countryCode(country){
        if(country == "IND"){
          this.country_code="+91"
        }else if(country == "SA"){
          this.country_code="+966"
        }else{
            this.country_code="+971"
        }
        this.contactError.mobile_number_valid=false;

  }

    onlyNumbers(event){
        var keycode = (event.which) ? event.which : event.keyCode;
        if ((keycode < 48 || keycode > 57) && keycode !== 13 || keycode == 46) {
           event.preventDefault();
           return false;
       }    
     }


    //store user raised complaints
    sendData(){
        this.err=false;
        this.resetError()
        this.errorHandler()
        if(!this.err){
            this.load=true;
            const data={
                "name":this.full_name,
                "mobile_number":`${this.country_code}${this.mobile_number}`,
                "email_id":this.email_id,
                "message":this.message,
                "subject":this.nature_of_enquiry,
                "country_code":this.country_code
            }

            this.subscriptions.push(this.home.sendContactData(data).subscribe(result=>{
                if(result.status){
                    this.clearData()
                    this.toast.successToastr(result['message'],"",{position:'top-right',toastTimeout:3000}) 
                    this.load=false;
                }else{
                    this.load=false;
                    this.toast.successToastr(result['message'],"",{position:'top-right',toastTimeout:3000}) 
                }
            }))

            

            
        }
    }

    resetError(){
        this.contactError={
            "full_name":false,
            "email_id":false,
            "email_id_valid":false,
            "mobile_number":false,
            "mobile_number_valid":false,
            "nature_of_enquiry":false,
            "message":false,
        }
    }

    clearData(){
            this.resetError()
            this.full_name=""
            this.mobile_number=""
            this.email_id=""
            this.message=""
            this.nature_of_enquiry=""
            this.country_code="+966"
    }

    //sets page title,meta title,meta descriptiuon and meta keywords dynamically
    getSeoDetails(){
        this.subscriptions.push(this.home.getSeoDetals("5").subscribe(result=>{
          if(result){
            this.seo.updateMetaTitle(result['response'])
          }
        }))
      }

      ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe());
      }


} 