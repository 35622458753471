import { Component, OnInit,OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HomeService } from '../../../SharedResources/Services/home.service';
import { CartService } from '../../../SharedResources/Services/cart.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { errorHandlerService } from '../../../SharedResources/Services/errorHandler.service';
import { sharedService } from '../../../SharedResources/Services/shared.service';
import { SeoService } from '../../../SharedResources/Services/seoService';
import { environment } from "../../../../environments/environment";
import { DomSanitizer} from '@angular/platform-browser';

declare const $;
declare const FB: any;

@Component({
    templateUrl: './productDetails.html',
    })

    export class productDetailsComponent implements OnInit,OnDestroy {
        public id:any;
        public banner_info:any={};
        public product_details:any={};
        public attribute_length:any;
        public relatedProducts:any=[];
        public images:any=[];
        public image:any;
        public cart_count:string;
        public wishlist_count:string;
        public currency:string="SAR";
        public quantity:any="1";
        public logged_in:boolean=false;
        public productLoaded:boolean=false;
        public load:boolean=false;
        public review_loader:boolean=false;
        public LANG:any;
        public name_on_cake:string;
        public review:any=[1,2,3,4,5]
        public reviewList:any=[]
        public size:string="10"
        public index:string="0"
        public count:any;
        public weight:any=[]
        public filling:any=[]
        public filling_by_choice:any=[]
        public candle:any=[]
        public selected_options:any=[]
        public price:any;
        public schema:any= {}
        public load_schema:boolean= false
        public subscriptions:Subscription[]=[]
        
        




        constructor(private home:HomeService,private route:ActivatedRoute,private shared:sharedService,private cart:CartService,private error:errorHandlerService,private router:Router,private toast:ToastrManager,private sanitizer: DomSanitizer,private seo:SeoService) {
          this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
                this.changeLanguage();
                this.getProductDetails();
          }))
          this.subscriptions.push(this.route.params
            .subscribe(
                (params:Params)=>{
                  this.id=atob(params['id'])
                }
                ))
                this.subscriptions.push(this.shared.currentCount.subscribe(count=>this.cart_count=count));
                this.subscriptions.push(this.shared.currentWishlistCount.subscribe(count=>this.wishlist_count=count));
                this.subscriptions.push(this.shared.currentUser.subscribe(user=>this.logged_in=user));
                if(localStorage.getItem('saadeddin_logged_in') != undefined){
                  this.logged_in=true;
                  this.shared.changeUser(true);
                }
                this.subscriptions.push(this.shared.wishlistChanged.subscribe(data=>{
                  data.data.wishlist=data.wishlist
                }));
                this.changeLanguage()
                window.scroll(0,0)
        }

        ngOnInit() {
            this.getProductDetails()
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        }

        changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
          this.currency="ريال"
        }
        else {
          this.LANG = environment.english_translation;
          this.currency="SAR"
        }
      }

        loadSlider(){
            $('.offer_slider').owlCarousel({
                margin: 10,
                dots:false,
                responsiveClass: true,
                responsive: {
                  0: {
                    items: 2,
                    nav: true,
                    stagePadding: 0,
                  },
                  600: {
                    items: 3,
                    nav: false,
                    stagePadding: 0,
                  },
                  1000: {
                    items: 4,
                    nav: true,
                    margin: 15,

                  }
                }
              })
        }

        show_review1(){
        $(document).ready(function() {
          $("#review_input1").toggleClass("open");
        });
        }

         zoom(e){
            let offsetX,offsetY,x,y; 
            const zoomer = e.currentTarget;
            e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
            e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
            x = offsetX/zoomer.offsetWidth*100
            y = offsetY/zoomer.offsetHeight*100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
          }

          getProductDetails(){
            this.subscriptions.push(this.home.productDetail(this.id).subscribe(result=>{
                if(result['id']){
                  this.banner_info=result['banner_info']
                  this.product_details=result;
                  this.price=this.product_details.price;
                  this.attribute_length=this.product_details.attribute.length
                  this.images=result['images']
                  this.product_details.options.map(data=>{
                    if(data.design_type == '1' && data.values.length > 0 && +data.values[0].price > 0){
                      data.values[0].selected = 1
                      this.calculateTotal()
                    }
                    if(data.design_type == "2" && data.values.length > 0){
                      if(data.values[0].extra){
                        data.show_filling="0"
                      }else{
                        data.show_fillings="1"
                      }
                    }
                  })
                  this.images.map(data=>{
                    data.back_image=this.sanitizer.bypassSecurityTrustStyle('url("' + data.image + '")')
                  })
                  if(this.images.length>0){
                    this.image=this.images[0].image
                  }
                  this.productLoaded=true;
                  this.product_details.title=this.decodeHtml(this.product_details.title)
                  this.product_details.description=this.decodeHtml(this.product_details.description)
                  this.product_details.estimated_delivery=this.formatDate(this.product_details.estimated_delivery)
                  this.product_details.attribute.map(data=>{
                    data.extra=this.decodeHtml(data.extra)
                  })
                  this.getReviewList()
                  this.getRelatedProducts()
                  if(+this.product_details.percentage > 0){
                    this.calculatePercentDiscount()
                  }
                  const data1:any={
                    "title":this.product_details.title,
                    "description":this.product_details.description,
                    "image":this.image,
                    "url": window.location.href
                  }
                  this.seo.updateMetaTitle(this.product_details.seo)
                  this.seo.setData(data1) 
                }else if(!result['id']){
                  this.router.navigate(['/'])
                }
              }))
          }



          //add dynamic json LD to the template
          setSchema(data:any){
            this.schema={
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": data.name,
              "image": data.images,
              "description": data.description,
              "sku": data.sku,
              "mpn": data.model,
              "review": {
                "@type": "Review",
                "reviewRating": {
                  "@type": "Rating",
                  "ratingValue": data.rating_count,
                  "bestRating": "5"
                },
                "author": {
                  "@type": "Person",
                  "name": "Saadeddin Pastries"
                },
              },
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": data.avg_rating,
                "reviewCount": data.rating_count
              },
              "offers": {
                "@type": "Offer",
                "url": data.url,
                "priceCurrency": "SAR",
                "price": data.price,
              }
            }
            this.load_schema=true
          }

          getRelatedProducts(){
            this.relatedProducts=[]
            this.subscriptions.push(this.home.relatedProducts(this.id).subscribe(result=>{
              if(result){
                this.relatedProducts=result['response']
                this.relatedProducts.map(data=>{
                  data.title=this.decodeHtml(data.title)
                })
                setTimeout(() => {
                  this.loadSlider()
                }, 100);
              }
            }))
          }

          

          addToCart(product_id,i,type,minus){
            
            const data:any={
              "product_id":product_id,
              "quantity":"1",
              "minus":minus
            }
            if(type == "related"){
              if(!this.logged_in){
                this.router.navigate(['/login'],{state:{product_id:product_id}})
                return
              }
              this.relatedProducts[i].load=true
            }else{
              const mandatory=this.product_details.options.filter(data=>{
                return data.is_mandatory=="1"
              })
              if(mandatory.length > 0 && minus=="0"){
                
                let d=[]
                 d=mandatory.map(data=>{
                  const selected_opt=data.values.filter(data1=>{
                    return data1.selected==1
                  })
                  if(selected_opt.length == 0){
                    return data.title
                  }
                })
                for(let i=0;i<d.length;i++){
                  if(d[i]){
                    this.toast.warningToastr(this.LANG.please_select+d[i],"",{position:'top-right',toastTimeout:3000})
                    this.load=false
                    return
                  }
                }
                
              }
              
              const options= JSON.parse(JSON.stringify(this.product_details.options)) 
              let weight_arr=[];
              options.map((data,index)=>{
                if(data.design_type =="1"){
                  const id=`#options${index}`
                  const weight=$(id).val();
                  if(weight){
                     weight_arr=data.values.filter(data1=>{
                        return data1.value_id == weight
                    })
                    data.values=weight_arr
                  }else{
                    data.values=[]
                  }
                }
                if(data.design_type =="2"){
                  const filling=data.values.filter(data=>{
                        return data.selected == 1
                  })
                  if(filling.length > 0){
                    data.values=filling
                  }else{
                    data.values=[]
                  }
                }
              })
              data['options']=options
              if(!this.logged_in){
                this.router.navigate(['/login'],{state:{product_id:product_id,options:options,name_on_cake:this.name_on_cake}})
                return
              }
            }
            if(this.name_on_cake){
              data['name_on_cake']=this.name_on_cake
            }
            this.load=true
            this.subscriptions.push(this.cart.insertCart(data).subscribe(result=>{
              if(result.status){
                  this.cart_count=result['extra']
                  this.shared.changeCount(this.cart_count)
                  if(type == "related"){
                    this.toast.successToastr(this.LANG.product_added_to_cart,"",{position:'top-right',toastTimeout:3000})
                    this.relatedProducts[i].load=false
                  }else{
                    if(minus == "0"){
                      this.product_details.quantity=parseInt(this.product_details.quantity) + 1
                      this.toast.successToastr(this.LANG.product_added_to_cart,"",{position:'top-right',toastTimeout:3000})
                    }else{
                      this.product_details.quantity=parseInt(this.product_details.quantity) - 1
                    }
                    this.product_details.price=this.price
                    this.product_details.options.map((data,index)=>{
                      if(data.design_type =="1"){
                          data.values.map(data1=>{
                            data1.selected=0
                          })
                          if(data.values.length > 0 && +data.values[0].price > 0){
                            data.values[0].selected = 1
                            const id=`#options${index}`
                            $(id).val(data.values[0].value_id);
                            this.calculateTotal()
                          }else{
                            const id=`#options${index}`
                            $(id).val("");
                          }
                      }else{
                          data.values.map(data1=>{
                            data1.selected=0
                          })
                      }
                      })
                    if(+this.product_details.percentage > 0){
                      this.calculatePercentDiscount()
                    }
                  }
                  this.name_on_cake=""
                  const a=document.getElementById('review1') 
                  if(a){
                    a.classList.remove("show")
                  }
                  this.load=false
                  
              }else{
                if(type == "related"){
                  this.relatedProducts[i].load=false
                }
                this.load=false
                this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
              }
          },respagesError => {
              if(type == "related"){
                this.relatedProducts[i].load=false
              }
              const error = this.error.getError(respagesError);
              if(error == "Gateway timeout"){
                return
              }
              this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
        }))
          }

          addToWishlist(product_id,wishlist,i,type){
            if(!this.logged_in){
              this.router.navigate(['/login'])
              return
            }
            const index="0";
            const size="0";
            this.subscriptions.push(this.cart.insertWishlist(product_id,index,size).subscribe(result=>{
                if(result.status){
                  if(type == "details"){
                    setTimeout(() => {
                      this.product_details.wishlist="1"
                    }, 500);
                  }
                    this.updateProduct(wishlist,i,type)
                    const count=parseInt(this.wishlist_count) + 1
                    this.shared.changeWishListCount(count.toString())
                    this.toast.successToastr(this.LANG.product_added_to_wishlist,"",{position:'top-right',toastTimeout:3000})
                }else{
                    if(type == "details"){
                      setTimeout(() => {
                        this.product_details.wishlist="0"
                      }, 500);
                    }
                    this.updateProduct(wishlist,i,type)
                    const count=parseInt(this.wishlist_count) - 1
                    this.shared.changeWishListCount(count.toString())
                    this.toast.successToastr(this.LANG.product_removed_from_wishlist,"",{position:'top-right',toastTimeout:3000})
                }
            },respagesError => {
                const error = this.error.getError(respagesError);
                if(error == "Gateway timeout"){
                  return
                }
                this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
          }))
        }

        updateProduct(wishlist,i,type){
          const wish= wishlist == "0" ? '1':'0'; 
          if(type == "related"){
            this.relatedProducts[i].wishlist=wish
          }
          // else{
          //   this.product_details.wishlist=wish
          // }
      }

      quickView(data){
        this.shared.changeProductDetails(data)
      }
        
      increaseQuantity(product_id,i,type){
        this.load=true
        this.addToCart(product_id,i,type,"0")
      }

      decreaseQuantity(product_id,i,type){
        this.load=true
        this.addToCart(product_id,i,type,"1")
      }

      onQuantityChange(quantity){
        if(parseInt(quantity) < 1){
            this.quantity=1
        }
      }

      onlyNumbers(event){
        var keycode = (event.which) ? event.which : event.keyCode;
        if ((keycode < 48 || keycode > 57) && keycode !== 13 || keycode == 46) {
           event.preventDefault();
           return false;
       }    
     }

     goToProductDetails(data){
      this.router.navigate(['/product/'+data.url_category+'/'+data.url_sub_category+'/'+data.url_title+'/'+ btoa(data.id)])
    }

    goToProductList(type){
      if(type == "cat"){
        this.router.navigate(['/'+this.product_details.url_category+'/'+btoa(this.product_details.category_id)])
      }else{
        this.router.navigate(['/'+this.product_details.url_category+'/'+this.product_details.url_sub_category+'/'+ btoa(this.product_details.category_id)+'/'+ btoa(this.product_details.sub_category_id)])
      }
    }

     fbShare(){
      FB.ui({
        method: 'feed',
          name: "Saadeddin Pastry shop",
          link: window.location.origin,
          picture: window.location.origin+"/assets/images/logo.png",
          description: "Check out awesome flavours of pastries and other sweets.!"
      })
    }

    decodeHtml(value) {
      let txt = document.createElement('textarea');
      txt.innerHTML = value;
      return txt.value;
  }

  ngOnDestroy(): void {
      this.subscriptions.map(s => s.unsubscribe());
  }

  //get products review list added by customer
  getReviewList(){
    this.subscriptions.push(this.home.reviewList(this.id,this.index,this.size).subscribe(result=>{
      if(result){
        this.reviewList=result['response']
        this.count=result['count']
        let sum:number=0;
        this.reviewList.map(data=>{
          data.comment=data.comment.replace(/\\n/g, '\n');
          sum+=parseInt(data.rating)
          if(data.created_on)
          data.created_on=this.formatDate(data.created_on)
        })
        const image=this.images.map(data=>{
              return data.image
        })
        const avg_rating=sum/parseInt(this.count) || 0
        const data={
          "name":this.product_details.title,
          "images": image,
          "description": this.product_details.description,
          "sku": this.product_details.sku,
          "model": this.product_details.model,
          "rating_count": this.count.toString(),
          "avg_rating": avg_rating.toString(),
          "url": window.location.href,
          "price": this.product_details.price,
        }
        this.setSchema(data)
      }
    }))
  }

  loadMore(){
    this.review_loader=true;
    this.index=(parseInt(this.index) + 1).toString();
    this.subscriptions.push(this.home.reviewList(this.id,this.index,this.size).subscribe(result=>{
      if(result){
        const reviewList=result['response']
        reviewList.map(data=>{
          data.comment=data.comment.replace(/\\n/g, '\n');
        })
        this.reviewList=[...this.reviewList,...reviewList]
        this.review_loader=false
        this.count=result['count']
      }else{
        this.review_loader=false
      }
    }))
  }


  selectFillings(id:any,i:any,j:any,value:number,multi_select:any,price:any){
    if(multi_select == "0"){
      this.product_details.options[i].values.map(data=>{
         data.selected=0
      })
    if(value == 1){
      this.product_details.options[i].values[j].selected=0
      let index = this.selected_options.findIndex( data => data.id == id)
      if(index != -1){
        this.selected_options.splice(index,1)
      }
      this.calculateTotal()
      if(+this.product_details.percentage > 0){
        this.calculatePercentDiscount()
      }
      return
    } 
    this.product_details.options[i].values[j].selected=1
    this.selected_options.push({id:id,price:price})
    this.calculateTotal()
  }else{
    if(value == 1){
      this.product_details.options[i].values[j].selected=0
      let index = this.selected_options.findIndex( data => data.value_id == id)

      if(index != -1){
        this.selected_options.splice(index,1)
      }
      this.calculateTotal()
      if(+this.product_details.percentage > 0){
        this.calculatePercentDiscount()
      }
      return
    } 
    this.product_details.options[i].values[j].selected=1
    this.selected_options.push({id:id,price:price})
    this.calculateTotal()
  }
  if(+this.product_details.percentage > 0){
    this.calculatePercentDiscount()
  }
  
  }

  calculateTotal(){
    this.product_details.price=this.price
    this.product_details.options.map(data=>{
      data.values.map(data1=>{
        if(data1.selected == 1){
          this.product_details.price=+this.product_details.price + +data1.price;
        }
      })
    })
  }

  selectSize(i:any){
    this.product_details.options[i].values.map(data=>{
      data.selected=0
    })
    const id=`#options${i}`
    const weight=$(id).val();
    let index = this.product_details.options[i].values.findIndex( data => data.value_id == weight)

    if(index != -1){
      this.product_details.options[i].values[index].selected = 1
    }

    this.calculateTotal()
    if(+this.product_details.percentage > 0){
      this.calculatePercentDiscount()
    }
    
  }

  selectCandle(index:any,value:number){
    if(value == 1){
      this.candle[index].selected=0
      return
    } 
    this.candle[index].selected=1
  }

  formatDate(value:any){
    const date=value.split(" ")[0]
    const day=date.split("-")[2]
    const month=date.split("-")[1]
    const year=date.split("-")[0]
      return `${this.getMonth(month)} ${day},${year}`
  }

  getMonth(index){
    const months = ["January","February","March","April","May",
    "June","July","August", "September","October","November","December"];
    return months[index-1]
  }


  calculatePercentDiscount(){
    this.product_details.discount=+this.product_details.price * +this.product_details.percentage/100
  }
       



    

    }