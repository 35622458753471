import { Component,OnInit, ViewChild, ElementRef } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { errorHandlerService } from '../../SharedResources/Services/errorHandler.service';
import { WindowService } from '../../SharedResources/Services/window.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from "../../../environments/environment";
import { UserService } from '../../SharedResources/Services/user.service';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { sharedService } from '../../SharedResources/Services/shared.service';
import { Subscription, timer, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { CartService } from '../../SharedResources/Services/cart.service';
import { SeoService } from '../../SharedResources/Services/seoService';
import { HomeService } from '../../SharedResources/Services/home.service';


declare const $;
@Component({
    templateUrl: './login.html',
})

export class loginComponent implements OnInit {
    public showLoginWithPwd:boolean=false;
    public err:boolean=false;
    public logged_in:boolean=false;
    public windowRef: any;
    public showOTPForm:boolean=false;
    public load:boolean=false;
    public count:number=0;
    public country_code:string="+973";
    public showResend:boolean=false;
    public mobile_number:string;
    public backup_mobile_number:string;
    public password:string;
    public confirm_password:string;
    public loginError:any={
        "mobile_number":false,
        "mobile_number_valid":false,
        "password":false,
        "password_valid":false,
        "otp":false,
        "otp_valid":false,
        "confirm_password":false,
        "password_match":false
    }
    @ViewChild('codeBox1', {static: false}) codeBox1:ElementRef;
    @ViewChild('codeBox2', {static: false}) codeBox2:ElementRef;
    @ViewChild('codeBox3', {static: false}) codeBox3:ElementRef;
    @ViewChild('codeBox4', {static: false}) codeBox4:ElementRef;   
    @ViewChild('codeBox5', {static: false}) codeBox5:ElementRef;
    @ViewChild('codeBox6', {static: false}) codeBox6:ElementRef;
    public redirect:boolean=false;
    public otpTimer:any;
    public token:string;
    public unSubscriptionTimer: Subscription;
    public LANG:any;
    public otp:string;
    public is_existing_user:boolean=false;
    public is_password_exist:boolean=false;
    public show_update:boolean=false;
    public destroyed = new Subject<any>();
    public cart_count:string;
    public forgot_pwd:boolean=false;
    public show_pwd_update:boolean=false;
    public product_id:any;
    public options:any;
    public name_on_cake:any;
    public subscriptions: Subscription[]=[];







    constructor(private error:errorHandlerService,private win: WindowService,private toast:ToastrManager,private user:UserService,private router:Router,private shared:sharedService,private cart:CartService,private seo:SeoService,private home:HomeService){
      this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
        if(location.pathname.includes("login")){
            this.changeLanguage();
        }
      }))
      if(localStorage.getItem('saadeddin_logged_in') != undefined){
        this.logged_in=true;
        this.shared.changeUser(true);
      }
      this.subscriptions.push(this.shared.currentToken.subscribe(token=>this.token=token));
      const a=this.router.getCurrentNavigation().extras.state;
            if(a){
                 this.redirect=a.redirect
                 this.product_id=a.product_id
                 this.options=a.options
                 this.name_on_cake=a.name_on_cake
            }
      window.scroll(0,0)

      
      
    }

    ngOnInit(){
        this.windowRef = this.win.windowRef
          this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container',{
                'size': 'invisible',
                'callback': function(response) {
                  this.checkMobile();
                }
              })
              this.changeLanguage()
              this.rememberMe()
              this.subscriptions.push(this.router.events.pipe(
                filter((event: RouterEvent) => event instanceof NavigationEnd),
                takeUntil(this.destroyed)
              ).subscribe(() => {
                  this.resetAll()
              }));
              setTimeout(() => {
                document.getElementById('mobile_number').focus()
            }, 100);
    }

    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
        }
        else {
          this.LANG = environment.english_translation;
        }
        this.getSeoDetails()
      }

    rememberMe(){
        $(".check-btn").click(function () {
            $(this).toggleClass("checked");
        });
    }

    resetAll(){
      this.resetLoginError();
      this.clearLoginDetails();
      this.is_existing_user=false;
      this.is_password_exist=false;
      this.showLoginWithPwd=false;
      this.showOTPForm=false;
      this.load=false;
      this.count=0;
      this.country_code="+973";
      this.show_pwd_update=false;
      this.forgot_pwd=false
      this.show_update=false
    }

    goToLoginWithPassword(){
        this.load=false;
        this.showOTPForm=false;
        this.showLoginWithPwd=true;
        const mobile=this.mobile_number
        this.resetLoginError()
        this.clearLoginDetails()
        this.mobile_number=mobile
        setTimeout(() => {
            document.getElementById('password').focus()
        }, 100);
    }

    goTologinWithOTP(){
        this.load=false;
        this.showLoginWithPwd=false;
        this.showOTPForm=false;
        const mobile=this.mobile_number
        this.resetLoginError()
        this.clearLoginDetails()
        this.mobile_number=mobile
    }

    getCodeBoxElement(index) {
        if(index===1){
          return this.codeBox1.nativeElement
        }
        if(index===2){
          return this.codeBox2.nativeElement
        }
        if(index===3){
          return this.codeBox3.nativeElement
        }
        if(index===4){
          return this.codeBox4.nativeElement
        }
        if(index===5){
          return this.codeBox5.nativeElement
        }
        if(index===6){
          return this.codeBox6.nativeElement
        }
      }
       onKeyUpEvent(index, event) {
        const eventCode = event.which || event.keyCode;
        if (this.getCodeBoxElement(index).value.length === 1) {
          if (index !== 6) {
            this.getCodeBoxElement(index+ 1).focus();
          } else {
            if(index == 6){
              return
            }
            this.getCodeBoxElement(index).blur();
          }
        }
        if (eventCode === 8 && index !== 1) {
          this.getCodeBoxElement(index - 1).focus();
        }
      }
       onFocusEvent(index) {
        for (let item = 1; item < index; item++) {
          const currentElement = this.getCodeBoxElement(item);
          if (!currentElement) {
              currentElement.focus();
              break;
          }
        }
      }
    
      keyPressed(event,index){
        var keycode = (event.which) ? event.which : event.keyCode;
              if ((keycode < 48 || keycode > 57) && keycode !== 13 || keycode == 46) {
                 event.preventDefault();
                 return false;
             } 
        if(this.codeBox1.nativeElement.value.length===1 && index==1) {
          return false;
        }
        if(this.codeBox2.nativeElement.value.length===1 && index==2) {
          return false;
        }
        if(this.codeBox3.nativeElement.value.length===1 && index==3) {
          return false;
        }
        if(this.codeBox4.nativeElement.value.length===1 && index==4) {
          return false;
        }
        if(this.codeBox5.nativeElement.value.length===1 && index==5) {
          return false;
        }
        if(this.codeBox6.nativeElement.value.length===1 && index==6) {
          return false;
        }
    
      }

      login(){
          if(this.showLoginWithPwd){
              this.loginWithPassword()
          }else{
              this.loginWithMobile()
          }
      }

      loginWithMobile(){
        this.err=false;
        this.resetLoginError();
        
        if(this.mobile_number==undefined || this.mobile_number==''){
          this.loginError.mobile_number  =true;
          this.err=true;	
        }
        this.mobileNumberValidation()

        

        if(this.err==false){
          this.load=true;
          this.checkPasswordExist()
        }
    }

    checkUser(){
      setTimeout(() => {
          this.load=false
          this.loginWithOTP()
      }, 3000);
    }

    loginWithOTP(){
      // this.sendOtp(this.mobile_number)
      this.otpFromFirebase(this.mobile_number)
    }

    mobileNumberValidation(){
      // if(this.country_code == "+966"){
      //   const re=/^([0]{1}[5]{1}[0-9]*)$/
      //   const re1=/^([5]{1}[0-9]*)$/
      //   if(this.loginError.mobile_number == false && !re.test(this.mobile_number) && !re1.test(this.mobile_number)){
      //     this.loginError.mobile_number_valid=true;
      //     this.err=true;	
      //   }

      //   if(this.loginError.mobile_number == false && re.test(this.mobile_number) && this.mobile_number.length != 10){
      //     this.loginError.mobile_number_valid=true;
      //     this.err=true;	
      //   }

      //   if(this.loginError.mobile_number == false && re1.test(this.mobile_number) && this.mobile_number.length != 9){
      //     this.loginError.mobile_number_valid=true;
      //     this.err=true;	
      //   }
      // }
      if(this.country_code == "+91"){
        if(this.loginError.mobile_number == false && this.mobile_number.length != 10){
          this.loginError.mobile_number_valid=true;
          this.err=true;	
        }
      }
      else{
        if(this.loginError.mobile_number == false && this.checkMobileNumber(this.mobile_number)){
          this.loginError.mobile_number_valid=true;
          this.err=true;	
        }
      }
    }

    loginWithPassword(){
        this.err=false;
        this.resetLoginError();
        
        if(this.mobile_number==undefined || this.mobile_number==''){
          this.loginError.mobile_number  =true;
          this.err=true;	
        }
        if(!this.is_password_exist){
          if(!this.loginError.password && this.confirm_password == ""){
            this.loginError.confirm_password=true;	
            this.err=true;
          }
  
          if(this.confirm_password == "" || this.confirm_password == undefined){
              this.loginError.confirm_password=true;	
              this.err=true;
          }
  
          if(!this.loginError.password_valid && this.password != this.confirm_password && !this.loginError.confirm_password){
            this.loginError.password_match=true;	
            this.err=true;
          }
        }

        this.mobileNumberValidation()


        if(this.password == undefined || this.password == ''){
            this.loginError.password=true;
            this.err=true;	
        }
        if(!this.loginError.password && this.checkPassword(this.password)){
          this.loginError.password_valid=true;	
          this.err=true;
      }

        if(this.err==false){
          this.load=true;
          if(this.forgot_pwd && this.show_pwd_update){
            this.updatePassword();
            return
          }
          const login_token=`${this.mobile_number}w8s7dghrteys`
            let data:any={"mobile_number":this.mobile_number,"password":this.user.encryptPassword(this.password),"country_code":this.country_code,"login_token":this.user.encryptPassword(login_token)};
            if(this.showLoginWithPwd && !this.is_password_exist){
              delete data['password']
              data.update_password= this.user.encryptPassword(this.password)
              data.otp= this.otp
            }
            this.loginUser(data)
        }
    }

    updatePassword(){
      const data={
          "password":this.user.encryptPassword(this.password),
          "mobile_number":this.mobile_number,
          "country_code":this.country_code
      }
      this.subscriptions.push(this.user.resetPassword(data).subscribe(result=>{
          if(result.status){
              this.load=false;
              const arabic=localStorage.getItem("arabic")
              localStorage.clear()
              if(!arabic || arabic == "true"){
                  localStorage.setItem("arabic","true")
                }else{
                  localStorage.setItem("arabic","false")
                }
              this.toast.successToastr(this.LANG.password_changed_successfully,"",{position:'top-right',toastTimeout:3000})
              this.router.navigate(['/login'])
          }else{
              this.load=false
              this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
          }
      }))
  }

    checkPassword(password:string){
      if(password.length < 8){
          return true
      }
  } 

  sendOtp(mobile_number){
      const num = `${this.country_code}${mobile_number}`;
      const data={
        "mobile_number":num,
      }
      this.subscriptions.push(this.user.sendOTP(data).subscribe(result=>{
        if(result.status){
          this.load=false;
          this.resetLoginError()
          this.showOTPForm=true;
          this.showResend=true;
          this.showLoginWithPwd=false
          this.is_existing_user=result['is_registered']
          setTimeout(() => {
            this.getCodeBoxElement(1).focus();
          }, 100);
        }else{
          this.load=false;
        }
      }))
  }

    otpFromFirebase(mobile_number,type?:number){
        const appVerifier = this.windowRef.recaptchaVerifier;

        const num = `${this.country_code}${mobile_number}`;
            
        firebase.auth().signInWithPhoneNumber(num, appVerifier)
                .then(result => {
                  this.load=false;
                  this.windowRef.confirmationResult = result;
                  if(type == 1){
                    this.resendOTP();
                    this.clearOtp();
                    const numbers = timer(31750);
                    this.unSubscriptionTimer=numbers.subscribe(() => { 
                      this.showResend=true; 
                    });
                    return
                  }
                  this.resetLoginError()
                  this.showOTPForm=true;
                  this.showResend=true;
                  this.showLoginWithPwd=false
                  this.is_existing_user=result['is_registered']
                  setTimeout(() => {
                    this.getCodeBoxElement(1).focus();
                  }, 500);
                })
                .catch( error => {
                this.load=false;
                });
       }

       resendAgain(){
        this.showResend=false
        this.count+=1;
        if(this.count<=3){
          // this.resend();
          this.otpFromFirebase(this.mobile_number,1);
        }else{
          this.toast.warningToastr(this.LANG.you_exceeded_OTP_request_max_attempt_please_try_after_some_time,"",{position:"top-right",toastTimeout:3000})
        }
      }

      resendOTP(){
        var timeleft = 30;
        this.otpTimer = setInterval(()=>{
      if(timeleft < 0){
        document.getElementById("countdown").innerHTML = "";
        clearInterval(this.otpTimer);
      } else {
        document.getElementById("countdown").innerHTML =`<p>${this.LANG.wait_for}${timeleft}${this.LANG.seconds_to_resend_OTP}</p>`;
      }
      timeleft -= 1;
      }, 1000);
      }

      resend(){
                const num = `${this.country_code}${this.mobile_number}`;
                const data={
                  "mobile_number":num,
                }
                this.subscriptions.push(this.user.sendOTP(data).subscribe(result=>{
                  if(result.status){
                    this.resendOTP();
                    this.clearOtp();
                    const numbers = timer(31750);
                    this.unSubscriptionTimer=numbers.subscribe(() => { 
                      this.showResend=true; 
                    });
                  }else{
                    this.load=false;
                  }
                }))
      }

      checkPasswordExist(){
        this.load=true;
        let data={"mobile_number":this.mobile_number,"country_code":this.country_code}
        this.subscriptions.push(this.user.checkMobile(data).subscribe(result=>{
          if(result['status'])
          {  
            if(result['is_registered'] == 1){
              this.is_existing_user=true
            }else{
              this.is_existing_user=false
              // this.sendOtp(this.mobile_number)
              this.otpFromFirebase(this.mobile_number)
            }
            if(result['is_password'] == 1){
              if(this.forgot_pwd && !this.show_pwd_update){
                // this.sendOtp(this.mobile_number)
                this.otpFromFirebase(this.mobile_number)
                return
              }
              this.is_password_exist=true;
              this.showLoginWithPwd=true;
              this.load=false;
              setTimeout(() => {
                document.getElementById('password').focus();
              }, 100);
            }else{
              this.is_password_exist=false
              // this.sendOtp(this.mobile_number)
              this.otpFromFirebase(this.mobile_number)
            }
          }else{
            if(this.forgot_pwd){
              this.load=false;
              this.toast.warningToastr(this.LANG.you_are_not_registered_with_us,"",{position:'top-right',toastTimeout:3000})
              return
            }
            // this.sendOtp(this.mobile_number)
            this.otpFromFirebase(this.mobile_number)
          }
            },respagesError => {
              this.load=false;
              const error = this.error.getError(respagesError);
              if(error == "Gateway timeout"){
                return
              }
              this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
        }));
      }

    checkMobile(){
        this.load=true;
        let data={"mobile_number":this.mobile_number,"country_code":this.country_code}
        this.subscriptions.push(this.user.checkMobile(data).subscribe(resPages=>{
          if(resPages['status'])
          {  
            if(this.forgot_pwd){
              this.load=false;
              this.show_update=true
              this.showLoginWithPwd=true; 
              this.showOTPForm=false; 
              this.is_password_exist=false
              this.show_pwd_update=true
              setTimeout(() => {
                document.getElementById('password').focus()
              }, 100);
              return
            }
            if(!this.is_password_exist){
              this.load=false;
              this.show_update=true
              this.showLoginWithPwd=true; 
              this.showOTPForm=false;
              setTimeout(() => {
                  document.getElementById('password').focus()
              }, 100);
              return
            }
            const login_token=`${this.mobile_number}w8s7dghrteys`
            let data:any={"mobile_number":this.mobile_number,"password":"","country_code":this.country_code,"login_token":this.user.encryptPassword(login_token)};
            if(this.showLoginWithPwd){
                data.password= this.user.encryptPassword(this.password)
            }
            this.loginUser(data)
          }
          else{
                this.load=false;
                this.router.navigate(['/registration'],{state:{mobile_number:this.mobile_number,country_code:this.country_code}})             
          }
            },respagesError => {
              this.load=false;
              const error = this.error.getError(respagesError);
              if(error == "Gateway timeout"){
                return
              }
              this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
        }));
    
      }

      loginUser(data){
        this.subscriptions.push(this.user.login(data).subscribe(results=>{
          if(results['customer_id'])
          {  
              this.load=false;
              this.logged_in=true;
              this.cart_count=results['cart'] || "0";
              this.shared.changeUser(true);
              this.shared.changeCount(this.cart_count);
              this.shared.changeToken(results['token']);
              this.shared.changeWishListCount(results['wishlist']);
              localStorage.setItem("saadeddin_logged_in", "1");
              localStorage.setItem("name", results['first_name']+" "+results['last_name']);
              localStorage.setItem("email_id", results['email_id']);
              localStorage.setItem("mobile_number", results['mobile_number']);
              localStorage.setItem("country_code", results['country_code']);
              localStorage.setItem("token", results['token']);
              this.toast.successToastr("",this.LANG.loggedin_successfull_welcome+results['first_name'],{position:'top-right',toastTimeout:3000})
              this.clearLoginDetails()
              if(this.redirect){
                this.router.navigate(['/checkout'])
              }else{
                this.router.navigate(['/'])
              }
              if(this.product_id){
                this.addToCart(this.product_id)
              }
            }else if(!results.status){
                this.load=false;
                if(results['extra'] == "2" && this.showLoginWithPwd){
                    this.password=""
                    this.toast.warningToastr(results['message'],"",{position:'top-right',toastTimeout:3000})
                    return
                }
                this.toast.warningToastr(results['message'],"",{position:'top-right',toastTimeout:3000})
            }
            },respagesError => {
              this.load=false;
              const error = this.error.getError(respagesError);
              if(error == "Gateway timeout"){
                return
              }
              this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
        }));
       }

    resetLoginError(){
        this.loginError={
            "mobile_number":false,
            "mobile_number_valid":false,
            "password":false,
            "password_valid":false,
            "otp":false,
            "otp_valid":false,
            "confirm_password":false,
            "password_match":false  
        }
    }

    checkMobileNumber(mobile:string){
        if(mobile.length < 8 || mobile.length > 10){
            return true
        }
    }

    verifyOTP(){
        const otp1=this.codeBox1.nativeElement.value || ""
        const otp2=this.codeBox2.nativeElement.value || ""
        const otp3=this.codeBox3.nativeElement.value || ""
        const otp4=this.codeBox4.nativeElement.value || ""
        const otp5=this.codeBox5.nativeElement.value || ""
        const otp6=this.codeBox6.nativeElement.value || ""
        const otp=`${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`
        this.err=false;
        this.resetLoginError();
        if(otp.length == 0){
            this.loginError.otp=true;
            this.err=true
        }
        if(otp.length > 0 && otp.length != 6){
            this.loginError.otp_valid=true;
            this.err=true
        }

        if(!this.err){
          //  this.verifyPHP(otp) 
          this.verifyFirebaseOTP(otp);
        }
    }

    verifyPHP(otp){
        this.load=true;
        this.otp=otp
        const num = `${this.country_code}${this.mobile_number}`;
        let data={ 
          "mobile_number":num,
          "otp":otp
        }
        this.subscriptions.push(this.user.verifyOTP(data).subscribe(result=>{
          if(result.status){
            this.resetLoginError()
            clearInterval(this.otpTimer);
            if(this.unSubscriptionTimer){
              this.unSubscriptionTimer.unsubscribe()
            }
            this.checkMobile()
          }else{
            this.load=false
            this.clearOtp()
            this.getCodeBoxElement(1).focus();
            this.toast.warningToastr("",this.LANG.invalid_OTP,{position:'top-right',toastTimeout:3000})
          }
        }));
    }

    verifyFirebaseOTP(otp){
      this.load=true;
      this.windowRef.confirmationResult
                .confirm(otp)
                .then((result:any) => {
                  this.resetLoginError()
                  clearInterval(this.otpTimer);
                  if(this.unSubscriptionTimer){
                    this.unSubscriptionTimer.unsubscribe()
                  }
                  this.checkMobile()
                })
                .catch( (error:any) => {
                  this.load=false;
                  var errorCode=error.code;
                  var a=errorCode.split('/')
                  if(a[1]=="invalid-verification-code"){
                    this.clearOtp();
                    this.getCodeBoxElement(1).focus();
                    this.toast.warningToastr("",this.LANG.invalid_OTP,{position:'top-right',toastTimeout:3000})
                  }else{
                    this.toast.warningToastr(this.LANG.Something_went_wrong_Please_try_again_later,"",{position:'top-right',toastTimeout:3000})
                  }
                });
    }

    

    clearLoginDetails(){
        this.mobile_number=""
        this.password=""
        this.clearOtp()
        this.count=0
        clearInterval(this.otpTimer);
        if(this.unSubscriptionTimer){
          this.unSubscriptionTimer.unsubscribe()
        }
    }

    clearOtp(){
      if(this.showOTPForm){
        this.codeBox1.nativeElement.value=""
        this.codeBox2.nativeElement.value=""
        this.codeBox3.nativeElement.value=""
        this.codeBox4.nativeElement.value=""
        this.codeBox5.nativeElement.value=""
        this.codeBox6.nativeElement.value=""
    }
    }

    onlyNumbers(event){
        var keycode = (event.which) ? event.which : event.keyCode;
        if ((keycode < 48 || keycode > 57) && keycode !== 13 || keycode == 46) {
           event.preventDefault();
           return false;
       }    
     }

     countryCode(country){
        if(country == "SA"){
          this.country_code="+973"
        }
        this.loginError.mobile_number_valid=false
  }

  ngOnDestroy(): void {
        if(this.unSubscriptionTimer){
          this.unSubscriptionTimer.unsubscribe()
        } 
        this.subscriptions.map(s => s.unsubscribe());
  }

  editMobileNumber(){
    const country_code=this.country_code
    const mobile_number=this.mobile_number
    this.resetAll()
    this.country_code=country_code
    this.mobile_number=mobile_number
  }

  forgotPassword(){
    this.editMobileNumber()
    this.forgot_pwd=true
  }

  addToCart(product_id){
    const data={
      "product_id":product_id,
      "quantity":"1"
    }
    if(this.options){
      data['options']=this.options
    }
    if(this.name_on_cake){
      data['name_on_cake']=this.name_on_cake
    }
    this.cart.insertCart(data).subscribe(result=>{
      if(result.status){
          this.cart_count=result['extra']
          this.shared.changeCount(this.cart_count)
          this.toast.successToastr(this.LANG.product_added_to_cart,"",{position:'top-right',toastTimeout:3000})
      }else{
          this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})

      }
  },respagesError => {
      const error = this.error.getError(respagesError);
      if(error == "Gateway timeout"){
        return
      }
      this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
  })
  }

  getSeoDetails(){
    this.subscriptions.push(this.home.getSeoDetals("8").subscribe(result=>{
      if(result){
        this.seo.updateMetaTitle(result['response'])
      }
    }))
  }

} 