import { Component,OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { environment } from "../../../environments/environment";
import { sharedService } from '../../SharedResources/Services/shared.service';
import { OrderService } from '../../SharedResources/Services/order.service';
import { SeoService } from '../../SharedResources/Services/seoService';
import { HomeService } from '../../SharedResources/Services/home.service';
import { Subscription } from 'rxjs';

declare const $;
declare const FB;

@Component({
    templateUrl: './payment-failed.html',
})

export class paymentFailed implements OnInit {
    public order_id:any;
    public LANG:any;
    public message:string;
    public message_heading:string;
    public image:any;
    public payment_status:boolean;
    public subscriptions:Subscription[]=[]
    public assets_path="https://saadeddin-production-static.s3.ap-south-1.amazonaws.com/assets"
    
    

    constructor(private router:Router,private route:ActivatedRoute,private loc: LocationStrategy,private shared:sharedService,private order:OrderService,private seo:SeoService,private home:HomeService){
      this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
        if(location.pathname.includes("payment-failed")){
            this.changeLanguage();
        }
      }))
      this.changeLanguage()
      this.subscriptions.push(this.route.queryParams
            .subscribe(
            (params:Params)=>{
              if(params['id']){
                this.order_id=atob(params['id'])
              }
            }
            ))
        window.scroll(0,0)

        // if(!this.order_id){
        //     this.router.navigate(['/'])
        // }
        history.pushState(null, null, window.location.href);  
        this.loc.onPopState(() => {
          history.pushState(null, null, window.location.href);
        });
        this.image=this.assets_path+"/images/cross.png";
    }

    ngOnInit(){

    }

    changeLanguage() {
      if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
        this.LANG = environment.arabic_translation;
        this.message=this.LANG.your_payment_was_failed;
        this.message_heading=this.LANG.sorry
      }else {
        this.LANG = environment.english_translation;
        this.message=this.LANG.your_payment_was_failed;
        this.message_heading=this.LANG.sorry
      }
    }

    

    ngOnDestroy(){
      history.pushState(null, null, window.location.href);  
        this.loc.onPopState(() => {
          history.pushState(null, null, window.location.href);
        });
        this.subscriptions.map(s => s.unsubscribe());
    }

    fbShare(){
      FB.ui({
        method: 'feed',
          name: "Saadeddin Pastry shop",
          link: window.location.origin,
          picture: this.assets_path+"/images/logo.png",
          description: "Check out awesome flavours of pastries and other sweets.!"
      })
    }

    popuptweet(){
      let text="Check out awesome flavors of pastries and other sweets.!"
      let urll=encodeURIComponent(window.location.origin);
      let url = 'https://twitter.com/intent/tweet?text='+text+'&url='+urll+'&hashtags=SaadedinPastries';
      let newwindow=window.open(url,'height=500,width=520,top=200,left=300,resizable');
    if (window.focus) {
      newwindow.focus()
    }
  }
  

  openReturnPolicy(){ 
      $("#return-policy").modal("show")
  }


    getMonth(index){
      const months = ["January","February","March","April","May",
      "June","July","August", "September","October","November","December"];
      return months[index-1]
    }

 

} 