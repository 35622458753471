import { Component,OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from "../../../../environments/environment";
import { sharedService } from '../../../SharedResources/Services/shared.service';
import { HomeService } from '../../../SharedResources/Services/home.service';
import { errorHandlerService } from '../../../SharedResources/Services/errorHandler.service';
import { Params, ActivatedRoute } from '@angular/router';
import * as S3 from 'aws-sdk/clients/s3';
import { AngularFireStorage } from '@angular/fire/storage';
import { SeoService } from '../../../SharedResources/Services/seoService';
import { Subscription } from 'rxjs';


declare const $:any;



@Component({
    templateUrl: './job-description.html',
    styleUrls:['./job-description.css']
})

export class JobDescriptionComponent implements OnInit { 
  public err:boolean=false
  public load:boolean=false
  public id:any;
  public careers:any={}
  public country_code: string="+973";
  public full_name:string;
  public position:string;
  public position_id:string;
  public mobile_number:string;
  public email_id:string;
  public message:string="";
  public LANG:any;
  public highlight:boolean=false
  public cv:any;
  public file:any;
  public file_name:string;
  public website:any;
  public unsubscriptions:Subscription[]=[];
  public country_list:any=[]
  public city_list:any=[]
  public district_list:any=[]
  public nationality_list:any=[]
  public qualification_list:any=[]
  public country_id:string=""
  public city_id:string=""
  public district_id:string=""
  public nationality_id:string=""
  public qualification_id:string=""
  public dob:any;
  public assets_path="https://saadeddin-production-static.s3.ap-south-1.amazonaws.com/assets"



  public careerError:any={
      "full_name":false,
      "email_id":false,
      "email_id_valid":false,
      "mobile_number":false,
      "mobile_number_valid":false,
      "nature_of_enquiry":false,
      "message":false,
      "country":false,
      "city":false,
      "nationality":false,
      "qualification":false,
      "dob":false,
  }
    
    constructor(private home:HomeService,private storage: AngularFireStorage,private route:ActivatedRoute,private error:errorHandlerService,private toast:ToastrManager,private shared:sharedService,private seo:SeoService){
      this.unsubscriptions.push(this.shared.getLanguage().subscribe((res) => {
        if(location.pathname.includes("job-description")){
            this.changeLanguage();
            this.getCareers()
        }
      }))
      this.unsubscriptions.push(this.route.params
            .subscribe(
                (params:Params)=>{
                  this.id=atob(params['id'])
                }
                ))
  }

    ngOnInit(){
      this.changeLanguage();
      this.getCareers()
      window.scroll(0,0)
    }

    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
        }
        else {
          this.LANG = environment.english_translation;
        }
        this.getSeoDetails()
      }

      closeForm(){
        $(".career_form").removeClass('show');
        $(".career_form_overlay").removeClass('show');
    }
    openForm(role:string,id:number){
        this.clearData()
        $(".career_form").addClass('show');
        $(".career_form_overlay").addClass('show');
        this.highlight=false
        this.position=role;
        this.position_id=id.toString()
    }      

    getCareers(){
        this.unsubscriptions.push(this.home.careers().subscribe(result=>{
            if(result){
                this.country_list=result.country;
                this.nationality_list=result.nationality;
                this.qualification_list=result.qualification;
                const careers=result['recentjobs'].filter(data=>{
                  return data.id==this.id
                })
                this.careers=careers[0]
                if(this.careers.email[0] != 'h' || this.careers.email[0] != 'H'){
                    this.website=`https://${this.careers.email}`
                }
            }
        }))
    }

    errorHandler(){
        if(this.full_name == undefined || this.full_name == ""){
            this.careerError.full_name=true;
            this.err=true;
        }
        if(this.email_id == undefined || this.email_id == ""){
            this.careerError.email_id=true;
            this.err=true;
        }

        if(this.mobile_number == ""  || this.mobile_number == undefined){
            this.careerError.mobile_number=true;	
            this.err=true;
        }

        if(this.country_id == ""  || this.country_id == undefined){
            this.careerError.country=true;	
            this.err=true;
        }

        if(this.city_id == ""  || this.city_id == undefined){
            this.careerError.city=true;	
            this.err=true;
        }

        if(this.nationality_id == ""  || this.nationality_id == undefined){
            this.careerError.nationality=true;	
            this.err=true;
        }

        if(this.qualification_id == ""  || this.qualification_id == undefined){
            this.careerError.qualification=true;	
            this.err=true;
        }

        if(this.dob == ""  || this.dob == undefined || this.dob == null){
            this.careerError.dob=true;	
            this.err=true;
        }

       

        if(!this.careerError.mobile_number && this.checkMobileNumber(this.mobile_number)){
            this.careerError.mobile_number_valid=true;	
            this.err=true;
        }

        if(this.checkEmail(this.email_id) && !this.careerError.email_id){
            this.careerError.email_id_valid=true;	
            this.err=true;
        }

        
    }

    checkEmail(email:string){
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(email)
    }

    checkMobileNumber(mobile:string){
        if(mobile.length < 9 || mobile.length > 10){
            return true
        }
    }

    onlyNumbers(event){
        var keycode = (event.which) ? event.which : event.keyCode;
        if ((keycode < 48 || keycode > 57) && keycode !== 13 || keycode == 46) {
           event.preventDefault();
           return false;
       }    
     }

    sendData(){
        this.err=false;
        this.resetError()
        this.errorHandler()
        if(!this.err){
            if(this.file == ""  || this.file == undefined){
                this.toast.warningToastr(this.LANG.Please_select_a_file_to_upload,"",{position:'top-right',toastTimeout:3000})
                return
            }
            this.load=true;
            const data={
                "name":this.full_name,
                "phone":this.mobile_number,
                "email":this.email_id,
                "message":this.message,
                "role":this.position,
                "cv":this.cv,
                "career_id":this.position_id,
                "country_id":this.country_id, 
                "city_id":this.city_id,
                "district_id":this.district_id,
                "nationality_id":this.nationality_id,
                "dob":this.dob,
                "qualification_id":this.qualification_id,
            }
            this.applyJob(data)            
        }
    }

    applyJob(data){
      this.uploadImage(this.file,callback=>{
          if(callback){
            data['cv']=`${this.cv}`
            this.unsubscriptions.push(this.home.applyJob(data).subscribe(result=>{
              if(result.status){
                  this.closeForm()
                  this.clearData()
                  this.toast.successToastr(this.LANG.thank_you_for_your_response,"",{position:'top-right',toastTimeout:3000}) 
                  this.load=false;
              }else{
                  this.toast.successToastr(result['message'],"",{position:'top-right',toastTimeout:3000}) 
                  this.load=false;
              }
          },respagesError => {
              this.load=false
              const error = this.error.getError(respagesError);
              this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
          }))
          }
      });
  }

    resetError(){
        this.careerError={
            "full_name":false,
            "email_id":false,
            "email_id_valid":false,
            "mobile_number":false,
            "mobile_number_valid":false,
            "nature_of_enquiry":false,
            "message":false,
            "country":false,
            "city":false,
            "nationality":false,
            "dob":false,
            "qualification":false,
        }
    }

    clearData(){
      this.resetError()
      this.full_name=""
      this.mobile_number=""
      this.email_id=""
      this.message=""
      this.position=""
      this.position_id=""
      this.file=""
      this.cv=""
      this.file_name="";
      this.city_id=""
      this.country_id=""
      this.district_id=""
      this.nationality_id=""
      this.qualification_id=""
      this.dob=""
      this.city_list=[]
      this.district_list=[]
      this.load=false;
    }

    openFile(){
        $("#resume").click()
    }

    fileChanged(event){
        var files = event.target.files;
        var file = files[0];
        if(files.length > 1){
            this.toast.warningToastr(this.LANG.cannot_add_more_files,"",{position:'top-right',toastTimeout:3000});
            return
        }
        var ext = file.name.split('.').pop();
        if(ext != "pdf" && ext != "docx" && ext != "doc"){
            this.toast.warningToastr(this.LANG.you_can_only_add_pdf_doc_or_docx_file,"",{position:'top-right',toastTimeout:3000});
            return
        }

        if(files.length > 0 && file) {
          this.file=file
          this.file_name=this.file.name
        }
    }

    onDrop(event: any) {
        event.preventDefault();
        event.stopPropagation();
        let dt = event.dataTransfer
        let files = dt.files
        if(files.length > 1){
            this.toast.warningToastr(this.LANG.cannot_add_more_files,"",{position:'top-right',toastTimeout:3000});
            return
        }
        let file=files[0]
        var ext = file.name.split('.').pop();
        if(ext != "pdf" && ext != "docx" && ext != "doc"){
            this.toast.warningToastr(this.LANG.you_can_only_add_pdf_doc_or_docx_file,"",{position:'top-right',toastTimeout:3000});
            return
        }

        if(files.length > 0 && file) {
          this.file=file
          this.file_name=this.file.name
        }

    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        var base64textString=btoa(binaryString);
        this.cv=base64textString
      }
    
    onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.highlight=true;
    }
    
    onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.highlight=false;
    }

    uploadFile(file:any,callback) {
      this.load=true;
      const contentType = file.type;
      const bucket = new S3(
            {
                accessKeyId: 'AKIA25UTM6GGGUCLVI4Q',
                secretAccessKey: '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',
                region: 'ap-south-1'
            }
        );
        const params = {
            Bucket: 'saddedin-images',
            Key:file.name,
            Body: file,
            ACL: 'public-read',
            ContentType: contentType
        };
        bucket.upload(params, (err, data) =>{
            if (err) {
                this.load=false;
                this.toast.warningToastr('There was an error uploading your file',"", );
                callback(false)
                return false;
            }
            this.cv=data.Location;
            callback(true)
        });
  }

    uploadImage(file:any,callback){
        this.load=true; 
        var n = Date.now()+file.name;
        if(file){
        const filePath = `Resumes/${n}`;
        const fileRef = this.storage.ref(filePath);
        fileRef.put(file)
        .then((snapshot) => {
            this.unsubscriptions.push(fileRef.getDownloadURL().subscribe((downloadURL)=> {
        this.cv=downloadURL
        callback(true)
    }));
      }).catch(err=>{
          console.log(err)
      })
        
    }
    }

    mailTo(){
        let url="https://mail.google.com/mail/?view=cm&fs=1&to="+this.careers.email;
          let newwindow=window.open(url,'name','height=500,width=520,top=200,left=300,resizable');
            if (window.focus) {
              newwindow.focus()
            }
      }

      getSeoDetails(){
        this.unsubscriptions.push(this.home.getSeoDetals("7").subscribe(result=>{
          if(result){
            this.seo.updateMetaTitle(result['response'])
          }
        }))
      }

      countryChanged(id:string){
        this.city_list=[]
        let index = this.country_list.findIndex( data => data.id == id)
        this.country_id=id;
        this.city_list=this.country_list[index].city;
      }

      cityChanged(id:string){
        this.district_list=[]
        let index = this.city_list.findIndex( data => data.id == id)
        this.country_id=id;
        this.district_list=this.city_list[index].district;
      }

      ngOnDestroy(): void {
        this.unsubscriptions.map(s => s.unsubscribe());
      }



} 