import { Component,OnInit, ViewChild, ElementRef,NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { } from '@agm/core/services/google-maps-types';
import { UserService } from '../../SharedResources/Services/user.service';
import { errorHandlerService } from '../../SharedResources/Services/errorHandler.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CartService } from '../../SharedResources/Services/cart.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import 'firebase/auth'; 
import { HeaderService } from '../../SharedResources/Services/header.service';
import { sharedService } from '../../SharedResources/Services/shared.service';
import { OrderService } from '../../SharedResources/Services/order.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from "../../../environments/environment";
import { SeoService } from '../../SharedResources/Services/seoService';
import { HomeService } from '../../SharedResources/Services/home.service';
import { Subscription } from 'rxjs';


declare const $;

@Component({
    templateUrl: './checkout.html',
})

export class checkoutComponent implements OnInit {
    public addressList: any = [];
    public selectedAddress:any={}
    public address_id: any;
    public cartList:any={}
    public cart_length:any;
    public storeList:any=[]
    public store_id:string="";
    public pickup_by:string="1";
    public currency:string="SAR";
    public address_length: any=0;
    public first_name: string;
    public last_name: string="";
    public id: any;
    public email_id: string="";
    public company: string="";
    public mobile_number: string;
    public country_code: string="+973";
    public alternate_country_code: string="+973";
    public optional_mobile_number:string="";
    public location: any;
    public landmark: any;
    public city_id: any;
    public location_type: string="1";
    public err: boolean=false;
    public load: boolean=false;
    public address_load: boolean=false;
    public pay_loading: boolean=false;
    public latitude = 24.774265;
    public longitude = 46.738586;
    public lat: any;
    public long: any;
    public addressClicked: boolean = false;
    public geoCoder: any;
    public address: string;
    public address_type:string="1"
    public address_count:string;
    public addressError:any={
        "first_name":false,
        "email_id":false,
        "email_id_valid":false,
        "mobile_number":false,
        "mobile_number_valid":false,
        "address":false,
        "optional_mobile_number_valid":false,
        "location_type":false,
    }
    
    @ViewChild("search", { static: false })
    public searchElementRef: ElementRef;
    public coupon_code:string;
    public coupon_applied: boolean=false;
    public total_product:any;
    public user_name:string;
    public user_mobile_number:string;
    public payment_type:string="2";
    public payment_option:any=1;
    public payment_option_word:string="VISA MASTERCARD MADA";
    public terms:boolean=false;
    public delivery_type:string="1";
    public cart_count:string;
    public wishlist_count:string;
    public draggable:boolean=true

    public formdata:any="";
    public LANG:any;
    public arabic:boolean=true;

    public currentMonth:any;
    public today:Date=new Date();
    public date:any;
    public dates:any=[];
    public prevDates:any=[];
    public dt = new Date();
    public prevMonth:any;
    public prevYear:any;
    public todays_date:any;
    public month:any;
    public format_date:any;
    public future_date:any;
    public selectedDate:any;
    public delivery_time:any=[];
    public schedule_load:boolean=false;
    public selectedTime:string;
    public showDeliveryTime:boolean=false;
    public pickup_time_text:string;
    public delivery_text:string;
    public only_pick_up:boolean=false;
    public subscriptions:Subscription[]=[]
    public only_online_payment:boolean=false;
    public payment_loaded:boolean=false;
    public prepaid_only:boolean=false;
    public wallet:string="0";
    public wallet_amount:any;
    public total_amount:number;
    public zoom: number = 8;
    public assets_path="https://saadeddin-production-static.s3.ap-south-1.amazonaws.com/assets"
    public pick_up_selected=false;














    constructor(private user:UserService,private cart:CartService,private mapsAPILoader: MapsAPILoader, private ngZone: NgZone,private error:errorHandlerService,private toast:ToastrManager,private router:Router,private order:OrderService,private header:HeaderService,private shared:sharedService,private sanitizer: DomSanitizer,private seo:SeoService,private home:HomeService){
        this.subscriptions.push(this.shared.getLanguage().subscribe((res) => {
            if(location.pathname.includes("checkout")){
                this.changeLanguage();
                this.getAddressList()
                this.getCartList()
                this.getStoreList()
            }
          }))
        this.subscriptions.push(this.shared.currentCount.subscribe(count=>this.cart_count=count));
        this.subscriptions.push(this.shared.currentWishlistCount.subscribe(count=>this.wishlist_count=count));
        if(localStorage.getItem('name') != undefined){
            this.user_name=localStorage.getItem('name');
        }
        if(localStorage.getItem('mobile_number') != undefined){
            const mobile=localStorage.getItem('mobile_number');
            const country_code=localStorage.getItem('country_code');
            this.mobile_number=mobile;
            this.country_code=country_code
            this.user_mobile_number=`${country_code}${mobile}`
        }
        window.scroll(0,0)
        this.changeLanguage()
    }

    ngOnInit(){
        this.getAddressList()
        this.getCartList()
        this.getAutoComplete()
        this.getStoreList()
    }

    //Change Language from arabic to english and vice-versa
    changeLanguage() {
        if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
          this.LANG = environment.arabic_translation;
          this.currency="ريال"
          this.pickup_time_text=this.LANG.choose_delivery_time
          this.arabic=true
          // $('#delivery_time').niceSelect('destroy');
          setTimeout(() => {
              // $("#delivery_time").niceSelect('update');
              this.delivery_text=this.LANG.select_delivery_time
          }, 100);
        }
        else {
          this.LANG = environment.english_translation;
          this.currency="SAR"
          this.pickup_time_text=this.LANG.choose_delivery_time
          this.arabic=false
          // $('#delivery_time').niceSelect('destroy');
          setTimeout(() => {
            // $("#delivery_time").niceSelect('update');
            this.delivery_text=this.LANG.select_delivery_time
        }, 100);
        }
        this.getSeoDetails()
      }


    loadSelect2(){
        $('.js-example-basic-single').select2();
        this.changeStoreId()
    }

     
    //get user address list
    getAddressList(){
        this.subscriptions.push(this.user.getUserAddress().subscribe(result=>{
            if(result){
                this.addressList=result['response']
                if(this.addressList.length == 0){
                    this.addAddress()
                    $('#address_picker').modal('show')
                }
            }
        }))
    }

    //get users default address
    getSelectedAddress(){
        this.subscriptions.push(this.user.getSelectedAddress().subscribe(result=>{
            if(result["id"]){
                this.address_id=result["id"]
                this.selectedAddress=result
                this.address_length=1
            }
        }))
    }

    //get user cart list
    getCartList(data?:any,check?:number){
        this.subscriptions.push(this.cart.cartList(data).subscribe(result=>{
          if(result['id']){
            this.cartList=result; 
            this.total_product=result['cart_Detail'].length
            this.coupon_code=result['coupon_code']
            this.wallet_amount=result['wallet_available']
            this.total_amount=result['grand_total']
            this.setPrePaidOnly(result['cart_Detail'])
            this.totalItemTrigger()
            this.cartList.cart_Detail.map(data=>{
                this.setProductsData(data)
            })
            if(this.coupon_code){
              this.coupon_applied=true
            }else{
              this.coupon_applied=false
            }  
            if(!data){
              this.compareDates()
            }
            if(!check){
              if(this.cartList.allow_delivery_above > this.cartList.grand_total){
                  this.showpickup("1")
                  this.only_pick_up=true
              }
              else{
                if(this.pick_up_selected){
                  this.pick_up_selected=false;
                  this.showpickup("1")
                  this.only_pick_up=false;
                  return
                }else{
                  this.pick_up_selected=true;
                  this.showDelivery()
                  this.only_pick_up=false
                }
                  // this.showDelivery()
              }
            }
          }if(!result['id']){
            this.cartList=result;
            this.total_product="0"
            this.shared.changeCount(this.total_product);
            this.router.navigate(['/home'])
          }
    },respagesError => {
        const error = this.error.getError(respagesError);
        if(error == "Gateway timeout"){
          return
        }
        this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
    }))
    }

    setProductsData(data){
      data.array_products=[]
      if(data.product_data){
        data.product_data=JSON.parse(data.product_data.replace(/\\/g,""))
        const objectArray = Object.entries(data.product_data);
        objectArray.forEach(([key, value]) => {
          if(key == "shape"){
            data.array_products.push({"key":this.LANG.shape,"value":value})
          }if(key == "total_character_price"){
            data.array_products.push({"key":this.LANG.Character_Price,"value":`${value} ${this.currency}`})
          }if(key == "total_candle_price"){
            data.array_products.push({"key":this.LANG.Candle_Price,"value":`${value} ${this.currency}`})
          }if(key == "image_on_cake_price"){
            data.array_products.push({"key":this.LANG.Image_Price,"value":`${value} ${this.currency}`})
          }if(key == "name_on_cake"){
            data.array_products.push({"key":this.LANG.Name_on_Cake,"value":value})
          }if(key == "name_on_cake_price"){
            data.array_products.push({"key":this.LANG.Name_Price,"value":`${value} ${this.currency}`})
          }if(key == "selected_decoration"){
            data.array_products.push({"key":this.LANG.Selected_Decoration,"value":value})
          }if(key == "decoration_price"){
            data.array_products.push({"key":this.LANG.Decoration_Price,"value":`${value} ${this.currency}`})
          }if(key == "total_flower_price"){
            data.array_products.push({"key":this.LANG.Flower_Price,"value":`${value} ${this.currency}`})
          }if(key == "total_ballon_price"){
            data.array_products.push({"key":this.LANG.Ballon_Price,"value":`${value} ${this.currency}`})
          }if(key == "total_gift_card_pric"){
            data.array_products.push({"key":this.LANG.Gift_Card_Price,"value":`${value} ${this.currency}`})
          }if(key == "gift_card_note"){
            data.array_products.push({"key":this.LANG.Gift_Card_Note,"value":value})
          }
        });
      }
      
    }

    walletToggle(selected?:string){
        if(selected){
          this.wallet=selected
        }else{
          this.wallet=this.wallet=='0' ? '1': '0';
        }
        if(this.wallet == "1"){
          if(+this.wallet_amount >= this.cartList.grand_total){
            this.cartList.grand_total=0;
            this.payment_type="2"
            this.only_online_payment=true;
            this.payment_loaded=true
            return
          }
          this.cartList.grand_total=this.cartList.grand_total - +this.wallet_amount
          this.payment_type="2"
        }else{
          // if(this.delivery_type == "1" && this.cartList.shipping > 0 && !this.pick_up_selected){
          //   this.cartList.grand_total=this.total_amount + this.cartList.shipping
          // }else{
            this.cartList.grand_total=this.total_amount
          // }
          // this.payment_type="2"
         
        }
        if(!this.prepaid_only){
          this.only_online_payment=false;
          this.payment_loaded=true;
        }
    }

    totalItemTrigger(){
        $(document).ready(function(){
          $(".total_items_trigger").click(function (){
           $(".main_Sub_cart-list").toggleClass("active");
           $(".total_items_trigger").toggleClass("active");
           });
         });
      }

    //get available stores list
    getStoreList(){
        this.subscriptions.push(this.header.storeList().subscribe(result=>{
            if(result){
                this.storeList=result['response']
                this.storeList.map(data=>{
                    if(data.open){
                        data.close=this.convertTime(data.close)
                    }
                    if(data.close){
                        data.open=this.convertTime(data.open)
                    }
                })
                setTimeout(() => {
                    this.loadSelect2()
                }, 100);
        }
        }))
    }

    convertTime (time) {
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) { 
          time = time.slice (1); 
          time[5] = +time[0] < 12 ? ' AM' : ' PM'; 
          time[0] = +time[0] % 12 || 12; 
        }
        return time.join (''); 
      }


    showDelivery(type?:number){ 
        if(!this.pick_up_selected){
            return
        }     
        $('.address_book_checkout_btns').removeClass('active');
        $("#pickfromDelivery").addClass('active');
        $("#address_checkout_books").addClass('active');
        $("#pickup_from_store").removeClass('active');
        this.delivery_type="1";
        if(type == 1){
          const data={
            "pickup_store_id":""
          }
          this.getCartList(data,1)
        }
        setTimeout(() => {
          if(this.wallet == "1" && (+this.wallet_amount >= (this.total_amount + this.cartList.shipping))){
            this.cartList.grand_total=0
            this.payment_type="1";
            this.only_online_payment=true
            this.payment_loaded=true
          }else if(this.wallet == "1" && (+this.wallet_amount < (this.total_amount + this.cartList.shipping))){
            this.cartList.grand_total=(this.total_amount + this.cartList.shipping) - +this.wallet_amount
            this.payment_type="2";
            this.only_online_payment=false
            this.payment_loaded=false
          }else{
            this.cartList.grand_total=this.cartList.grand_total + this.cartList.shipping
          }
        }, 1000);
        this.store_id=""
        $('#store_id').val("").trigger('change');
        this.pickup_time_text=this.LANG.choose_delivery_time;
        this.pick_up_selected=false;
    }

    showpickup(type?:string){
        if(this.pick_up_selected){
          return
        }  
        $('.address_book_checkout_btns').removeClass('active');
        $("#pickfromStore").addClass('active');
        $("#address_checkout_books").removeClass('active');
        $("#pickup_from_store").addClass('active');
        this.delivery_type="2";
        setTimeout(() => {
          if(type != "1"){
              this.cartList.grand_total=this.cartList.grand_total - this.cartList.shipping
              if(this.cartList.grand_total < 0){
                this.cartList.grand_total=0
              }
          }  
        }, 500);
        this.pickup_time_text=this.LANG.choose_pickup_time
        this.pick_up_selected=true
    }

    acceptAlert(){
     $("#text_alerts_btn").toggleClass('active');
    }

    pickupMethod1(){
        $("#pickupmethodBtn1").addClass('active');
        $("#pickupmethodBtn").removeClass('active');
        this.pickup_by="2"
    }

    pickupMethod(){
        $("#pickupmethodBtn1").removeClass('active');
        $("#pickupmethodBtn").addClass('active');
        this.pickup_by="1"
    }


    goToPayment(){
        if(!this.address_id){
            this.toast.warningToastr(this.LANG.please_add_an_address_before_proceeding_forward,"",{position:'top-right',toastTimeout:3000});
            return
        }
        this.router.navigate(['/payment-options'])
    }

    getAutoComplete() {
        this.mapsAPILoader.load().then(() => {
            this.geoCoder = new google.maps.Geocoder;
            let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
            autocomplete.addListener("place_changed", () => {
                
                this.ngZone.run(() => {
                    let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                    if (place.geometry === undefined || place.geometry === null) {
                        
                        return;
                    }

                    this.lat = place.geometry.location.lat();
                    this.long = place.geometry.location.lng();
                    this.latitude = place.geometry.location.lat();
                    this.longitude = place.geometry.location.lng();
                    this.address = place.formatted_address;
                    this.addressError.address=false;
                    this.addressClicked = true;
                    this.zoom=18
                });
            });
        });
    }

    onSelectAddress(event) {
        this.latitude = event.coords.lat;
        this.longitude = event.coords.lng;
        this.getAddress(event.coords.lat, event.coords.lng)
        this.addressClicked = true;
        this.addressError.address=false
    }

    markerDragEnd(event){
        this.latitude = event.coords.lat;
        this.longitude = event.coords.lng;
        this.getAddress(event.coords.lat, event.coords.lng)
        this.addressClicked = true;
        this.addressError.address=false
    }

    getAddress(latitude, longitude) {
        this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
            if (status == 'OK') {
                if (results[0]) {
                    this.lat = latitude;
                    this.long = longitude;
                    this.address = results[0].formatted_address;
                    this.zoom=18
                } else {
                    this.toast.warningToastr("",this.LANG.no_result_found, { position: "top-right", toastTimeout: 3000 });
                }
            } else {
                this.toast.errorToastr(this.LANG.geocoder_failed_due_to + status, "Error!", { position: "top-right", toastTimeout: 3000 });
            }

        });
    }

    saveAddress(){
        this.err=false;
        this.resetAddressError()
        this.errorHandler()
        if(!this.err){
            this.address_load=true
            this.checkCity(result=>{
                if(result){
                    let data = {
                        "id": this.id,
                        "first_name": this.first_name,
                        "last_name": this.last_name,
                        "address_1": this.address,
                        "address_2": this.address,
                        "pincode": "",
                        "company":this.company,
                        "email_id":this.email_id,
                        "latitude": this.lat,
                        "longitude": this.long,
                        "phone": this.mobile_number,
                        "type": this.address_type,
                        "alternate_phone":this.optional_mobile_number,
                        "city_id": this.city_id,
                        "action": "1",
                        "address_count": this.address_length
                    }
                        this.saveUserAddress(data)
                }
            })
        }
    }

    changeSelectedAddress(data){
        if(data.type == "1"){
            return
        }
        data.type="1"
        data.action="2"
        this.saveUserAddress(data)
    }

    //add,update and delete users address
    saveUserAddress(data){
        this.subscriptions.push(this.user.saveAddress(data).subscribe(result => {
            if(result.status){
                $('#address_picker').modal('hide');
                if(data.action == "1"){
                    this.toast.successToastr(this.LANG.address_added_successfully,"",{position:'top-right',toastTimeout:3000})
                }else{
                    this.toast.successToastr(this.LANG.delivery_address_changed,"",{position:'top-right',toastTimeout:3000})
                }
                this.address_load=false
                this.getAddressList()
            }else{
                this.address_load=false
                this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
            }
        },respagesError => {
            this.address_load=false
            const error = this.error.getError(respagesError);
            if(error == "Gateway timeout"){
                return
              }
            this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
        }))
    }

    closeModal() {
        $('#address_picker').modal('hide');
    }

    checkCity(callback){
        const data={
         "lat":this.lat,
         "long":this.long
        }
        this.subscriptions.push(this.user.checkCity(data).subscribe(results=>{
            if(results['id']){
             this.city_id=results['id']
                callback(true)
            }else if(!results.status){
                this.address_load=false
                this.toast.warningToastr(results['message'],"",{ position: "top-right", toastTimeout: 3000 })
            }
        },respagesError => {
         this.address_load=false
         const error = this.error.getError(respagesError);
         if(error == "Gateway timeout"){
             return
           }
         this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
     }))
     }

    errorHandler(){
        if(this.first_name == undefined || this.first_name == ""){
            this.addressError.first_name=true;
            this.err=true;
        }

        if (this.address == "" || this.address == undefined || this.lat == "" || this.long == "" || this.lat == undefined || this.long == undefined) {
            this.addressError.address = true;
            this.err = true;
        }

        if(this.checkEmail(this.email_id) && this.email_id){
          this.addressError.email_id_valid=true;	
          this.err=true;
        }

        if(!this.addressError.mobile_number && this.checkMobileNumber(this.mobile_number)){
            this.addressError.mobile_number_valid=true;	
            this.err=true;
        }

        if (this.optional_mobile_number && this.checkMobileNumber(this.optional_mobile_number)) {
            this.addressError.optional_mobile_number_valid = true;
            this.err = true;
        }
        // if(this.alternate_country_code == "+973"){
        //     const re=/^([0]{1}[5]{1}[0-9]*)$/
        //     const re1=/^([5]{1}[0-9]*)$/
        //     if(this.optional_mobile_number && !re.test(this.optional_mobile_number) && !re1.test(this.optional_mobile_number)){
        //       this.addressError.optional_mobile_number_valid=true;
        //       this.err=true;	
        //     }
    
        //     if(this.optional_mobile_number && re.test(this.optional_mobile_number) && this.optional_mobile_number.length != 10){
        //       this.addressError.optional_mobile_number_valid=true;
        //       this.err=true;	
        //     }
    
        //     if(this.optional_mobile_number && re1.test(this.optional_mobile_number) && this.optional_mobile_number.length != 9){
        //       this.addressError.optional_mobile_number_valid=true;
        //       this.err=true;	
        //     }
        // }
    }

    checkEmail(email:string){
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(email)
    }

    checkMobileNumber(mobile:string){
        if(mobile.length <8 || mobile.length > 10){
            return true
        }
    }

    addAddress(){
        this.resetAddressError()
        this.clearAddressFields()
    }

    resetAddressError(){
        this.addressError={
            "first_name":false,
            "mobile_number":false,
            "mobile_number_valid":false,
            "optional_mobile_number_valid":false,
            "address":false,
            "location_type":false,
        } 
    }

    clearAddressFields(){
                this.address_load=false
                this.id=""
                this.first_name=""
                this.last_name=""
                this.address=""
                this.company=""
                this.email_id=""
                this.lat=""
                this.long=""
                this.latitude = 24.774265;
                this.longitude = 46.738586;
                this.optional_mobile_number=""
                this.city_id=""
                this.addressClicked=false;
                this.zoom=8
    }

    onlyNumbers(event){
        var keycode = (event.which) ? event.which : event.keyCode;
        if ((keycode < 48 || keycode > 57) && keycode !== 13 || keycode == 46) {
           event.preventDefault();
           return false;
       }    
     }

    
    logout(){
        const data={}
        this.subscriptions.push(this.header.logout(data).subscribe(result=>{
            if(result){
                localStorage.clear()
                firebase.auth().signOut().then(function() {
                  }).catch(function(error) {
                  });
                this.shared.changeUser(false)
                this.shared.changeCount("0")
                this.shared.changeWishListCount("0")
                this.toast.successToastr(this.LANG.logout_successfully,"",{position:'top-right',toastTimeout:3000})
                this.router.navigate(['/login'],{state:{redirect:true}})
            }else{
                this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
            }
        },respagesError => {
            const error = this.error.getError(respagesError);
            if(error == "Gateway timeout"){
              return
            }
            this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
        }))
    }

    applyCoupon(){
        if(this.coupon_code == "" || this.coupon_code == undefined){
            this.toast.warningToastr(this.LANG.enter_valid_coupon,"",{position:'top-right',toastTimeout:3000});
            document.getElementById('coupon').focus();
            return
        }

        this.load=true
        this.couponCode("apply")
    }
    
    removeCoupon(){
        this.load=true
        this.couponCode("remove")
    }

  //apply and remove coupon code
  couponCode(type){
    let store_id=$('#store_id').val();
    const data={
      "coupon_code":this.coupon_code,
      "deliverydate":this.selectedDate,
      "pickup_store_id":store_id,
    }
    if(type == "remove"){
      data.coupon_code=""
    }
    this.subscriptions.push(this.cart.couponCode(data).subscribe(result=>{
      if(result['id']){
       this.cartList=result;
       this.coupon_code=result['coupon_code']
       this.wallet_amount=result['wallet_available']
       this.total_amount=result['grand_total']
       this.cartList.grand_total=result['grand_total'];
       this.setPrePaidOnly(result['cart_Detail'])
       this.walletToggle(this.wallet)
       this.cartList.cart_Detail.map(data=>{
        this.setProductsData(data)
        })
       if(type == "apply"){
         this.coupon_applied=true;
         this.toast.successToastr(this.LANG.coupon_code_applied,"",{position:'top-right',toastTimeout:3000})
        }else{
          this.coupon_applied=false;
          this.toast.successToastr(this.LANG.coupon_code_removed,"",{position:'top-right',toastTimeout:3000})
        }
        this.load=false
        if(this.cartList.allow_delivery_above > this.cartList.grand_total){
            this.pick_up_selected=false;
            this.showpickup("1")
            this.only_pick_up=true
          }
          else{
            if(this.pick_up_selected){
              this.pick_up_selected=false;
              this.showpickup("1")
              this.only_pick_up=false;
              return
            }else{
              this.pick_up_selected=true;
              this.showDelivery()
              this.only_pick_up=false
            }
          }
      }else if(!result.status){
        this.load=false
        this.toast.warningToastr("",result['message'],{position:'top-right',toastTimeout:3000})
      }
    },respagesError => {
      this.load=false
      const error = this.error.getError(respagesError);
      if(error == "Gateway timeout"){
        return
      }
      this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
  }))
  }

  //increases product quantity by 1 unit
  increaseQuantity(product_id,quantity,i){
    this.cartList.cart_Detail[i].load=true
    const newQuantity=parseInt(quantity) + 1
    this.updateCart(product_id,newQuantity,i)
  }

  //decrease product quantity by 1 unit
  decreaseQuantity(product_id,quantity,i){
    this.cartList.cart_Detail[i].load=true
    const newQuantity=parseInt(quantity) - 1
    this.updateCart(product_id,newQuantity,i)
  }

  //delete product from the cart
  deleteCart(product_id,i){
    const newQuantity="0"
    this.updateCart(product_id,newQuantity,i)
  }

  //update cart products
  updateCart(product_id,newQuantity,i){
    const data={
      "cart_detail_id":product_id,
      "quantity":newQuantity.toString()
    }
    this.subscriptions.push(this.cart.updateCart(data).subscribe(result=>{
      if(result['id']){
          this.cartList=result;
          this.total_product=result['cart_Detail'].length;
          this.wallet_amount=result['wallet_available']
          this.total_amount=result['grand_total']
          this.cartList.grand_total=result['grand_total']
          this.cartList.cart_Detail.map(data=>{
            this.setProductsData(data)
          })
          this.setPrePaidOnly(result['cart_Detail'])
          this.coupon_code=result['coupon_code']
          if(this.coupon_code){
            this.coupon_applied=true
          } 
          this.shared.changeCount(this.total_product)
          if(newQuantity == '0'){
              this.compareDates()
          }
          if(this.cartList.allow_delivery_above > this.cartList.grand_total){
            this.pick_up_selected=false;
            this.showpickup("1")
            this.only_pick_up=true;
            window.scroll(0,0)
          }else{

            if(this.pick_up_selected){
              this.pick_up_selected=false;
              this.showpickup("1")
              this.only_pick_up=false;
              return
            }else{
              this.pick_up_selected=true;
              this.showDelivery()
              this.only_pick_up=false
            }
            
            // this.pick_up_selected=true;
            // this.showDelivery()
            // this.only_pick_up=false;
          }
          // this.walletToggle(this.wallet)
      }else if(!result['id']){
        this.cartList=result;
        this.total_product="0"
        this.shared.changeCount(this.total_product)
        this.router.navigate(['/home'])
      }
      else{
        this.cartList.cart_Detail[i].load=false
        this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
      }
  },respagesError => {
      this.cartList.cart_Detail[i].load=false
      const error = this.error.getError(respagesError);
      if(error == "Gateway timeout"){
        return
      }
      this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
  }))
  }

  //add products to wishlist
  addToWishlist(product_id,i,cart_id){
    const index="0";
    const size="0";
    this.subscriptions.push(this.cart.insertWishlist(product_id,index,size).subscribe(result=>{
        if(result.status){
            const count=parseInt(this.wishlist_count) + 1
            this.shared.changeWishListCount(count.toString())
            this.deleteCart(cart_id,i)
            this.toast.successToastr(this.LANG.product_added_to_wishlist,"",{position:'top-right',toastTimeout:3000})
        }else{
            const count=parseInt(this.wishlist_count) - 1
            this.shared.changeWishListCount(count.toString())
            this.toast.successToastr(this.LANG.product_removed_from_wishlist,"",{position:'top-right',toastTimeout:3000})
      }
    },respagesError => {
        const error = this.error.getError(respagesError);
        if(error == "Gateway timeout"){
          return
        }
        this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
  }))
}

  //change payment method fron COD to online and vice-versa
  changePaymentMethod(type){
    this.payment_type=type.toString()
  }

  changePaymentOption(type:any){
    this.payment_option=type
  }

    termsCondition(){
        this.terms=!this.terms
    }

    openTermsConditions(){ 
        $("#terms-conditions").modal("show")
    }

    //place an order
    submitOrder(){
        if(!this.terms){
                this.toast.warningToastr(this.LANG.please_accept_the_terms_conditions_to_proceed_forward,"",{position:'top-right',toastTimeout:3000})
                return
        }
        const selected_address=this.addressList.filter(data=>{
            return data.type=="1"
        })
        
        let store_id:any="";
        if(this.delivery_type == "2"){
            store_id=$('#store_id').val();
            this.store_id=store_id
            if(!store_id){
                this.toast.warningToastr(this.LANG.please_select_pickup_store_location,"",{position:'top-right',toastTimeout:3000})
                return
            }
            this.address_id="0"
        }else{
            if(selected_address.length<1){
                this.toast.warningToastr(this.LANG.please_select_delivery_address,"",{position:'top-right',toastTimeout:3000})
                return
            }
            this.address_id=selected_address[0].id
             store_id="";
             this.store_id="";
             this.pickup_by="0"
        }

        const delivery_time=$("#delivery_time").val();
        if(!delivery_time){
            this.toast.warningToastr(this.LANG.please_select_delivery_time,"",{position:'top-right',toastTimeout:3000})
            return
        }
        const data={
            "address_id":this.address_id, 
            "payment_type":this.payment_type,
            "pickup_store_id":store_id,
            "deliverydate":this.selectedDate,
            "deliverytime":delivery_time,
            "pickup_by":this.pickup_by,
            "order_from":"1",
            "wallet":this.wallet
        } 
        this.pay_loading=true
        this.subscriptions.push(this.order.orderSubmit(data).subscribe(result=>{
            if(result.status){                
                if(result['token']){
                    this.paymentGateway(result['token'].tid)
                }else {
                  this.pay_loading=false
                  this.router.navigate(['/thank-you'],{state:{order_id:result['extra'],expected_delivery_date:result['expected_delivery_date']}})
                  this.shared.changeCount("0")
                }
            }else{
                this.pay_loading=false
                this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
            }
        },respagesError => {
            this.pay_loading=false;
            const error = this.error.getError(respagesError);
            if(error == "Gateway timeout"){
              return
            }
            this.toast.errorToastr(error,"Error!",{position:'top-right',toastTimeout:3000})
        }))

        
    }

    goToProductDetails(data){
        if(data.product_id == "100001" || data.product_id == "100000" || data.product_id == "100002"){
            return
          }
        this.router.navigate(['/product/'+data.url_category+'/'+data.url_sub_category+'/'+data.url_title+'/'+ btoa(data.product_id)])
      }

      countryCode(country){
        if(country == "IND"){
          this.country_code="+91"
        }else if(country == "SA"){
          this.country_code="+973"
        }else{
            this.country_code="+971"
        }
    }

  alternateCountryCode(country){
    if(country == "IND"){
      this.alternate_country_code="+91"
    }else if(country == "SA"){
      this.alternate_country_code="+973"
    }else{
        this.alternate_country_code="+971"
    }
    this.addressError.optional_mobile_number_valid=false

}

    clearSelectedAddress(){       
        this.lat=""
        this.long=""
    }

    //triggers paymenty gateway Event and redirect users to payment gateway page
    paymentGateway(tid){
        const data={
            "tid":tid,
            "payment_option":this.payment_option_word
        }
        this.subscriptions.push(this.order.paymentGateway(data).subscribe((res:any)=>{
          if(res.status){
              location.replace(res.url)
              return
          }
          this.toast.warningToastr(res.messgae,"",{position:'top-right',toastTimeout:3000})
        },error=>{
            // this.formdata=this.sanitizer.bypassSecurityTrustHtml(error.error.text);
            // setTimeout(() => {
            //     document.forms["frm"].submit();
            // }, 100);
        }))
    }

    //gets available delivery date and time
    getDeliveryDetails(date){
        this.schedule_load=true
        const newDate=this.formatDate(date);
        this.selectedDate=newDate;
        const data={
            "date":newDate
        }
        this.subscriptions.push(this.order.deliveryTime(data).subscribe(results=>{
            if(results){
              this.delivery_time=results['response']
              this.schedule_load=false;
              this.showDeliveryTime=true;
              setTimeout(() => {
                this.delivery_text=this.LANG.select_delivery_time
                // $('#delivery_time').niceSelect();  
                  $('#delivery_time').select2();
              }, 100);
            }
        },respagesError => {
          this.schedule_load=false;
          const error = this.error.getError(respagesError);
          if(error == "Gateway timeout"){
            return
          }
          this.toast.errorToastr(error,this.LANG.ERROR,{position:'top-right',toastTimeout:3000})
      }))
    }
 
    getDate(){
        this.prevDates=[]
        this.dates=[]
        this.dt.setDate(1);
        var day = this.dt.getDay();
        var endDate = new Date(
            this.dt.getFullYear(),
            this.dt.getMonth() + 1,
            0
            ).getDate();
        
        var prevDate = new Date(
            this.dt.getFullYear(),
            this.dt.getMonth(),
            0
            ).getDate();
        
        
        var today = new Date(this.today);
        var months = [
        "January",
        "February",
        "March",
        "April",
        "May" ,
        "June" ,
        "July" ,
        "August" , 
        "September" ,
        "October" ,
        "November" ,
        "December"
         ];
        
         
        const year:any=this.dt.getFullYear();
        document.getElementById("date").innerHTML = year;
        document.getElementById("month").innerHTML = months[this.dt.getMonth()];
        
        for(let x=day;x>0;x--){
            const full_date=`${this.prevYear}-${this.prevMonth}-${prevDate-x+1}`
            this.prevDates.push({"date":prevDate-x+1,"month":this.prevMonth,"year":this.prevYear,"full_date":full_date})
        } 
               
        for(let i = 1; i <= endDate;i++){
            let date:any;
            if(i < 10){
                date =  `0${i}`
            }else{
                date=`${i}`
            }
            if (i == today.getDate() && this.dt.getMonth() == today.getMonth()) {
                const full_date=`${year}-${this.month}-${date}`
                this.dates.push({"date":date,"today":true,"month":this.month,"year":year,"full_date":full_date,"lesser":false})
            }else{
                const full_date=`${year}-${this.month}-${date}`
                this.dates.push({"date":date,"today":false,"month":this.month,"year":year,"full_date":full_date,"lesser":false})
            }
            this.dates.map(data=>{
              const comp = data.full_date.localeCompare(this.todays_date);
              if(comp == -1){
                  data.lesser=true
              }
            })
        }

      }


    setCurrentDates(){
        this.prevMonth=this.today.getMonth()
        this.month = this.today.getMonth()+1
        if (this.month < 10){
            this.month  = '0' + this.month;
        } 
        this.prevYear = this.today.getFullYear()
        this.date= String(this.today.getDate()).padStart(2, '0');
        this.currentMonth=this.today.toLocaleString('default', { month: 'long' })
        this.format_date=this.formatDate(this.today)
        this.todays_date=this.formatDate(this.today)
        this.future_date=new Date(this.today)
        this.future_date.setDate(this.future_date.getDate() + 6)
        this.future_date=this.formatDate(this.future_date)
      }

      getSelectedDate(full_date,date,month){
        const comp = full_date.localeCompare(this.todays_date);
        if(comp == -1){
            return
        }
        if(full_date == this.format_date){
                return
          }
        this.schedule_load=true
        this.selectedTime="";
        this.format_date=full_date;
        this.delivery_time=[];
        this.date=date;
        this.currentMonth=this.getMonth(month);
        this.showDeliveryTime=false;
        const data={
          "deliverydate":this.format_date,
        }
        this.getCartList(data)
        this.getDeliveryDetails(full_date)
        
    }

    getMonth(index){
        const months = ["January","February","March","April","May",
        "June","July","August", "September","October","November","December"];
        return months[index-1]
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }

    moveDate(type){
        if(type == 'prev'){
            this.dt.setMonth(this.dt.getMonth()-1);
            if(this.month == "01"){
                this.month="13"
            }
            if(this.prevMonth == "01"){
                this.prevYear-=1
                this.prevMonth="13"
            }
            this.prevMonth=parseInt(this.prevMonth)-1
            this.month=parseInt(this.month)-1;
        }else if(type== 'next'){
            this.dt.setMonth(this.dt.getMonth() + 1);
            if(this.month == "12"){
                this.month="0"
            }
            if(this.prevMonth == "12"){
                this.prevYear+=1
                this.prevMonth="0"
            }
            this.prevMonth=parseInt(this.prevMonth)+1
            this.month=parseInt(this.month)+1;
        }
        if(this.month < 10){
            this.month=`0${this.month}`
        }
        if(this.prevMonth < 10){
            this.prevMonth=`0${this.prevMonth}`
        }
        this.getDate()
    }

    compareDates(){
        var max = this.cartList.cart_Detail[0].estimated_delivery.split(" ")[0];
        this.cartList.cart_Detail.map(data=>{
            const newDate=data.estimated_delivery.split(" ")[0]
            if (this.fDate(newDate) > this.fDate(max)){
                max = newDate;
            }
        })
        if(max != "0000-00-00"){
          this.today=new Date(max)
        }
        this.getDeliveryDetails(this.today) 
        this.setCurrentDates()
        this.dt=new Date(this.today)
        this.getDate();
    }

    fDate(s) {
        var d = new Date();
        s = s.split('-');
        d.setFullYear(s[0]);
        d.setMonth(s[1]);
        d.setDate(s[2]);
        return d;
      }

      //sets page title,meta title,meta descriptiuon and meta keywords dynamically
      getSeoDetails(){
        this.subscriptions.push(this.home.getSeoDetals("4").subscribe(result=>{
          if(result){
            this.seo.updateMetaTitle(result['response'])
          }
        }))
      }

      ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe());
      }

      useCurrentLocation(){
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.setGeoAddress);
          } else {
            this.toast.warningToastr("Geolocation is not supported by this browser.","",{position:'top-right',toastTimeout:3000})
          }
      }

      setGeoAddress=(event)=> {
        this.latitude = event.coords.latitude ;
        this.longitude = event.coords.longitude;
        this.getAddress(event.coords.latitude , event.coords.longitude)
        this.addressClicked = true;
        this.addressError.address=false
     }   


     setPrePaidOnly(data){
         for(let i=0;i<data.length;i++){
            if(data[i].payment_option == "2"){
                this.only_online_payment=true;
                this.prepaid_only=true
                break
            }else{
                this.only_online_payment=false;
            }
        }
        this.payment_loaded=true
     }

     changeStoreId(){
      $("#store_id").on("change",  (e)=> { 
        if(e.target.value){
        const data={
          "pickup_store_id":e.target.value
        }
        this.getCartList(data,1) 
      }
      });
     }




} 