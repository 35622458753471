import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { HeaderService } from '../../Services/header.service';
import { Router } from '@angular/router';
import { sharedService } from '../../Services/shared.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { errorHandlerService } from '../../Services/errorHandler.service';
import { HomeService } from '../../Services/home.service';
import { CartService } from '../../Services/cart.service';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { environment } from "../../../../environments/environment";
import { Subscription } from 'rxjs';

declare const $;
declare const FB: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.html', 
    })

    export class headerComponent implements OnInit {
        public product_details:any;
        public cart_count:string;
        public wishlist_count:string;
        public category:any;
        public load:boolean=false;
        public quantity_load:boolean=false;
        public logged_in:boolean=false;
        public facebook:string;
        public whatsapp_number:string;
        public instagram:string;
        public twitter:string;
        public youtube:string;
        public play_store:string;
        public contact_number:string;
        public quantity:any="1";
        public currency:string="SAR";
        public search_keyword:string;
        public search_results:any=[];
        public result_tab:boolean=false;
        public no_result:boolean=false;
        public LANG:any;
        public token:string;
        public cake_builder:any;
        public view:boolean=false;
        public language:any;
        public lang_display:any;
        public language_option:any;
        public flag_img:any;
        public flag_img_1:any;
        public name_on_cake:string;
        public data:any={};
        public show_profile_links:boolean=false;
        public subscriptions:Subscription[]=[];
        public assets_path="https://saadeddin-production-static.s3.ap-south-1.amazonaws.com/assets"
        
        @Output() footerData:EventEmitter<any> = new EventEmitter();    


        constructor(private header:HeaderService,private router:Router,private home:HomeService,private cart:CartService,private shared:sharedService,private error:errorHandlerService,private toast:ToastrManager) {
          const lang=localStorage.getItem("arabic")
          if(!lang){
            localStorage.setItem("arabic","true")
          }
          if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
            this.LANG=environment.arabic_translation
            this.language = "English";
            this.lang_display = "Ar";
            this.langChange()
          }
          else {
            this.LANG=environment.english_translation
            this.language=this.LANG.arabic
            this.lang_display = "En";
            this.langChange()
          }
            this.subscriptions.push(this.shared.currentCount.subscribe(count=>this.cart_count=count));
            this.subscriptions.push(this.shared.currentWishlistCount.subscribe(count=>this.wishlist_count=count));
            this.subscriptions.push(this.shared.currentUser.subscribe(user=>this.logged_in=user));
            this.subscriptions.push(this.shared.currentToken.subscribe(token=>{
              this.token=token
              // this.cake_builder=window.location.origin.split(":")[0]+"/cake-builder/home/"+this.token;
              this.cake_builder="https://www.saadeddin.com/cake-builder/home/"+this.token;
            }));
            this.subscriptions.push(this.shared.currentDetails.subscribe(data=>{
                if(data){
                    this.quantity="1"
                    this.getProductDetails(data.id)
                    this.data=data
                }
            }));
            if(localStorage.getItem('saadeddin_logged_in') != undefined){
                this.logged_in=true;
                this.shared.changeUser(true);
            }

            if(localStorage.getItem('token') != undefined){
              this.token=localStorage.getItem('token');
              this.cake_builder="https://www.saadeddin.com/cake-builder/home/"+this.token;
          }

          
        }



  mobMenuToggle=()=>{
      $("#menu_container").addClass("show");
      $("body").addClass("side-opened");
      setTimeout(()=> {
        $("#menu_container").addClass("active");
      }, 100);
  }

  showSubmenu(data){
      data.showmenu = !data.showmenu;
      $("#menu_container").addClass("sub_menu_added");
  }

closeMenu=()=>{
    $("#menu_container").removeClass("active");
    $(".menu_links").removeClass("show_submenu");
    $("#menu_container").removeClass("sub_menu_added");
    $("body").removeClass("side-opened");
     setTimeout(()=> {
        $("#menu_container").removeClass("show");
      }, 400);
}



        ngOnInit() {
            firebase.initializeApp(environment.firebaseConfig);
            this.openMenu();
            this.getMenu();
            this.facebookSDK();
            if($(window).width() < 520)
              {
                this.view = true;
              }
              $(document).mouseup((e)=> 
              {
                  var container = $("#search-area");

                  if (!container.is(e.target) && container.has(e.target).length === 0) 
                  {
                    this.result_tab = false;
                    this.no_result = false;
                    this.search_results=[];
                    this.search_keyword=""
                  }
              });

              $("body").addClass('site_loaded');
        }

        //Change Language from arabic to english and vice-versa
        changeLang=()=>{
          // $("#preloader_holder").addClass('show');
          if (this.language=="English"){
            localStorage.setItem("arabic","true")
          //   $("HTML").addClass("ar");
          //   this.LANG = environment.arabic_translation;
          //   this.currency="ريال"
          //   this.language = this.LANG.arabic;
          //   this.language_option="English"
          //   this.flag_img=this.assets_path+"/images/icons/saudi-flag.png"
          //   this.flag_img_1=this.assets_path+"/images/icons/en.png";
          //   this.lang_display = "En";
          }else{
            localStorage.setItem("arabic","false") ;
          //   $("HTML").removeClass("ar");
          //   this.LANG = environment.english_translation;
          //   this.language = "English";
          //   this.currency="SAR"
          //   this.language_option=this.LANG.arabic
          //   this.flag_img=this.assets_path+"/images/icons/en.png"
          //   this.flag_img_1=this.assets_path+"/images/icons/saudi-flag.png";
          //   this.lang_display = "Ar";

          }

          // this.shared.setLanguage(location.pathname);
          // this.getMenu();  

          
          setTimeout(() => {
            // $("#preloader_holder").removeClass('show');
                location.reload()
          }, 100);

  }

  //Change Language from arabic to english and vice-versa
  langChange=()=>{
    let body = document.getElementsByTagName('html')[0]
    if (this.language=="English"){
      localStorage.setItem("arabic","true")
      // $("HTML").addClass("ar");
      body.classList.add("ar");
      this.currency="ريال"
      this.LANG = environment.arabic_translation;
      this.language = this.LANG.arabic;
      this.language_option="English"
      this.flag_img=this.assets_path+"/images/icons/saudi-flag.png"
      this.flag_img_1=this.assets_path+"/images/icons/en.png";
      this.lang_display = "En";
    }else{
      localStorage.setItem("arabic","false")
      // $("HTML").removeClass("ar");
      body.classList.remove("ar");
      this.currency="SAR"
      this.LANG = environment.english_translation;
      this.language = "English";
      this.language_option=this.LANG.arabic
      this.flag_img=this.assets_path+"/images/icons/en.png"
      this.flag_img_1=this.assets_path+"/images/icons/saudi-flag.png";
      this.lang_display = "Ar";

    }
    
  }

        openMenu=()=>{
            $(document).ready(()=> {
                $("[data-trigger]").on("click", function(e){
                    e.preventDefault();
                    e.stopPropagation();
                    var offcanvas_id =  $(this).attr('data-trigger');
                    $(offcanvas_id).toggleClass("open");
                    $("#site_header").toggleClass("expanded_header");
                    $(this).toggleClass("clicked");
                    $(".screen-overlay").addClass("show");
                }); 

            
                $(".screen-overlay").click(()=>{
                    $(".screen-overlay").removeClass("show");
                    $("#overlay").removeClass("open");
                    $("#toggle").removeClass("clicked");
                    $("#site_header").removeClass("expanded_header");
                }); 
            }); 
            $(".language").click(function () {
                $(this).toggleClass("show");
            });
            
        }

        //get dynamic menu data
        getMenu=()=>{
          this.subscriptions.push(this.header.menu().subscribe(result=>{
                if(result){
                    this.category=result['category'];
                    this.cart_count=result['cart'] || "0";
                    this.wishlist_count=result['wishlist'];
                    this.facebook=result['footer'].facebook;
                    this.whatsapp_number=result['footer'].whatsapp_number;
                    this.instagram=result['footer'].instagram;
                    this.twitter=result['footer'].twitter;
                    this.youtube=result['footer'].youtube;
                    this.play_store=result['footer'].play_store;
                    this.contact_number=result['footer'].contact_number;
                    this.shared.changeCount(this.cart_count)
                    this.shared.changeWishListCount(this.wishlist_count)
                    this.footerData.emit(result['footer']);

                }
            },respagesError =>{
                const error = this.error.getError(respagesError);
                if(error == "Gateway timeout"){
                    const arabic=localStorage.getItem("arabic")
                    localStorage.clear()
                    if(!arabic || arabic == "true"){
                      localStorage.setItem("arabic","true")
                    }else{
                      localStorage.setItem("arabic","false")
                    }
                    firebase.auth().signOut().then(()=> {
                    }).catch((error)=> {
                    });
                    this.shared.changeUser(false)
                    setTimeout(() => {
                      this.getMenu()
                    }, 1000);
                }
            }))
        }

        goToProductList=(category_name:string,category_id:any,subcategory_name:string,subcategory_id:any)=>{
          if(subcategory_id){
              this.router.navigate(['/'+category_name+'/'+subcategory_name+'/'+ btoa(category_id) +'/'+ btoa(subcategory_id)])
          }else{
              this.router.navigate(['/'+category_name+'/'+ btoa(category_id)])
          }
          this.closeNav()
        }


        closeNav=()=>{
            $(".screen-overlay").removeClass("show");
            $("#overlay").removeClass("open");
            $("#toggle").removeClass("clicked");
            $("#site_header").removeClass("expanded_header");
        }

        open_account=()=>{
            $("#acc_options").toggleClass("show");
            $(".menu_dropdown-toggle").toggleClass("show");
        }

        open_profile=()=>{
            var element = document.getElementById("open_profile");
            var element1 = document.getElementById("profile_list");
            element.classList.toggle("show");
            element1.classList.toggle("show");
        }

        //logged user out of website
        logout=()=>{
            const data={}
            this.subscriptions.push(this.header.logout(data).subscribe(result=>{
                if(result){
                    const arabic=localStorage.getItem("arabic")
                    localStorage.clear()
                    if(!arabic || arabic == "true"){
                      localStorage.setItem("arabic","true")
                    }else{
                      localStorage.setItem("arabic","false")
                    }
                    firebase.auth().signOut().then(()=> {
                      }).catch((error)=> {
                      });
                    this.logged_in=false;
                    this.shared.changeUser(false)
                    this.shared.changeCount("0")
                    this.shared.changeWishListCount("0")
                    this.toast.successToastr(this.LANG.logout_successfully,"",{position:'top-right',toastTimeout:3000})
                    this.router.navigate(['/'])
                    this.closeNav()
                    this.closeProfileLinks()
                }else{
                    this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
                }
            },respagesError => {
                const error = this.error.getError(respagesError);
                if(error == "Gateway timeout"){
                  return
                }
                this.toast.errorToastr(error,this.LANG.error,{position:'top-right',toastTimeout:3000})
            }))
        }

        //get product details for quickview
        getProductDetails=(id)=>{
          this.subscriptions.push(this.home.productDetail(id).subscribe(result=>{
                if(result){
                  this.product_details=result
                  this.product_details.title=this.decodeHtml(this.product_details.title)
                  this.product_details.description=this.decodeHtml(this.product_details.description)
                  setTimeout(() => {
                    $("#itempopup").modal("show")
                  }, 300);
                }
              }))
        }

        //increase products quantity by 1 unit
        increaseQuantity=(product_details)=>{
            this.quantity_load=true
            this.addToCart(product_details,"0")
          }
    
          //decrease products quantity by 1 unit
          decreaseQuantity=(product_details)=>{
            this.quantity_load=true
            this.addToCart(product_details,"1")
          }

          onQuantityChange=(quantity)=>{
            if(parseInt(quantity) < 1){
                this.quantity=1
            }
          }
    
          onlyNumbers=(event)=>{
            var keycode = (event.which) ? event.which : event.keyCode;
            if ((keycode < 48 || keycode > 57) && keycode !== 13 || keycode == 46) {
               event.preventDefault();
               return false;
           }    
         }

        //add product to cart
        addToCart=(product_details,minus)=>{
            if(!this.logged_in){
                this.router.navigate(['/login'])
                $("#itempopup").modal("hide")
                return
              }

              const mandatory=product_details.options.filter(data=>{
                    return data.is_mandatory == "1"
              })


              if(mandatory.length > 0){
                $("#itempopup").modal("hide")
                this.router.navigate(['/product/'+product_details.url_category+'/'+product_details.url_sub_category+'/'+product_details.url_title+'/'+ btoa(product_details.id)])
                return
              }
            this.load=true
            const data={
              "product_id":product_details.id,
              "quantity":"1",  
              "minus":minus
            }
            if(this.name_on_cake){
              data['name_on_cake']=this.name_on_cake
            }
            this.subscriptions.push(this.cart.insertCart(data).subscribe(result=>{
              if(result.status){
                  this.cart_count=result['extra']
                  this.shared.changeCount(this.cart_count)
                  this.shared.emitcartChanged(result['extra'])
                  this.quantity_load=false
                  this.load=false
                  if(minus == "0"){
                    this.product_details.quantity=parseInt(this.product_details.quantity) + 1
                    this.toast.successToastr(this.LANG.product_added_to_cart,"",{position:'top-right',toastTimeout:3000})
                  }else{
                    this.product_details.quantity=parseInt(this.product_details.quantity) - 1
                  }
                  this.name_on_cake=""
                  const a=document.getElementById('review')
                  if(a){
                    a.classList.remove("show")
                  }
              }else{
                  this.toast.warningToastr(result['message'],"",{position:'top-right',toastTimeout:3000})
                  this.load=false
              }
          },respagesError => {
              this.load=false
              const error = this.error.getError(respagesError);
              if(error == "Gateway timeout"){
                return
              }
              this.toast.errorToastr(error,this.LANG.error,{position:'top-right',toastTimeout:3000})
          }))
          }


           openHeaderSearch=()=>{
                $("#header_search").toggleClass("active");
            }

          addToWishlist=(product_id,wishlist)=>{
            if(!this.logged_in){
                this.router.navigate(['/login'])
                $("#itempopup").modal("hide")
                return
              }
            const index="0";
            const size="0";
            this.subscriptions.push(this.cart.insertWishlist(product_id,index,size).subscribe(result=>{
                if(result.status){
                    this.updateProduct(wishlist,product_id)
                    const count=parseInt(this.wishlist_count) + 1
                    this.shared.changeWishListCount(count.toString())
                    this.toast.successToastr(this.LANG.product_added_to_wishlist,"",{position:'top-right',toastTimeout:3000})
                }else{
                    this.updateProduct(wishlist,product_id)
                    const count=parseInt(this.wishlist_count) - 1
                    this.shared.changeWishListCount(count.toString())
                    this.toast.successToastr(this.LANG.product_removed_from_wishlist,"",{position:'top-right',toastTimeout:3000})
                }
            },respagesError => {
                const error = this.error.getError(respagesError);
                if(error == "Gateway timeout"){
                  return
                }
                this.toast.errorToastr(error,this.LANG.error,{position:'top-right',toastTimeout:3000})
          }))
        }

        updateProduct=(wishlist,product_id)=>{
            const wish= wishlist == "0" ? '1':'0';
            this.product_details.wishlist=wish
            const data={
              id:product_id,
              wishlist:wish,
              data:this.data
            }
            this.shared.wishlistChanged.emit(data)
        }

          contactUser=()=>{
            let mobile=this.contact_number
            location.href="tel:"+mobile
          } 

          zoom=(e)=>{
            let offsetX,offsetY,x,y;
            const zoomer = e.currentTarget;
            e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
            e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
            x = offsetX/zoomer.offsetWidth*100
            y = offsetY/zoomer.offsetHeight*100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
          }

          onSearchClick=(data)=>{
            this.result_tab=false;
            this.search_results=[];
            this.search_keyword=""
            if(data.extra == "1"){
                this.router.navigate(['/'+data.url_title+'/'+btoa(data.id)])
            }else if(data.extra == "2"){
              this.router.navigate(['/'+data.url_category+'/'+data.url_title+'/'+ btoa(data.category_id)+'/'+ btoa(data.id)])
            }else if(data.extra == "3"){
                this.router.navigate(['/product/'+data.url_category+'/'+data.url_sub_category+'/'+data.url_title+'/'+ btoa(data.id)])
            } 
          }

      search=($event)=>{
        setTimeout(() => {
          let query= $event.target.value;
          if(query.length>=2){
            let data={"search":query}
            this.subscriptions.push(this.header.search(data).subscribe(resPages => {
              if(this.search_keyword.length > 0){
                if(resPages['response'].length<1){
                      this.search_results=resPages['response'];
                      this.result_tab=true;
                      this.no_result=true
                      setTimeout(()=>{
                        this.result_tab=false;
                        this.no_result=false;
                      },3000) 
                  }else{
                      this.search_results=resPages['response'];
                      this.result_tab=true;
                      this.no_result=false
                  }
                }
            }));
            }else{
              this.search_results=[]
              this.result_tab=false;
              this.no_result=false;
            }
        }, 100);
        setTimeout(() => {
          if($event.target.value.length <= 0){
            this.search_results=[]
            this.result_tab=false;
            this.no_result=false
          }
        }, 500);
        }

        clearSearch=()=>{
            this.search_keyword=""
            this.search_results=[]
            this.result_tab=false;
            this.no_result=false
          }

          facebookSDK=()=>{
            window['fbAsyncInit'] = ()=> {
              FB.init({
                appId      : '261591181623053',
                cookie     : true,
                xfbml      : true,
                version    : 'v7.0'
              });
                
              FB.AppEvents.logPageView();   
                
            };
            
            (function(d, s, id){
               var js, fjs = d.getElementsByTagName(s)[0];
               if (d.getElementById(id)) {return;}
               js = d.createElement(s); js.id = id;
               js.src = "https://connect.facebook.net/en_US/sdk.js";
               fjs.parentNode.insertBefore(js, fjs);
             }(document, 'script', 'facebook-jssdk'));
            }

            goToMyOrders=()=>{
              this.router.navigate(['/my-profile',{type:btoa("orders")}])
              this.closeProfileLinks()
              this.closeNav()
            }

            goToCakeBuilder=()=>{
              if(!this.logged_in){
                this.router.navigate(['/login'])
                this.closeNav()
                return
              }
              location.href=this.cake_builder
            }

            decodeHtml=(value)=> {
              let txt = document.createElement('textarea');
              txt.innerHTML = value;
              return txt.value;
          }

          openWhatsApp=()=>{
            let url;
            if($(window).width() < 769)
             {
                url="https://wa.me/"+this.whatsapp_number;               
              }else{
                url="https://web.whatsapp.com/send?phone="+this.whatsapp_number
              }
              window.open(url,"whatsapp");
          }

         profileClicked=()=>{
          this.show_profile_links=!this.show_profile_links
         }

         closeProfileLinks=()=>{
          this.show_profile_links=false
         }

         ngOnDestroy(): void {
          this.subscriptions.map(s => s.unsubscribe());
        }

    

    }